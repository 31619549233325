/*!

=========================================================
* Paper Dashboard PRO React - v1.3.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';
// javascript plugin used to create scrollbars on windows
import PerfectScrollbar from 'perfect-scrollbar';
import { Redirect, Route, Switch, useLocation, Link } from 'react-router-dom';

import SplashNavbar from '../components/Navbars/SplashNavbar.js';
import Footer from '../components/Footer/Footer.js';

import routes from '../routes.js';
import { Card, Row, CardBody, Col, CardTitle, CardSubtitle, Button, CardText } from 'reactstrap';
import livingroom from 'assets/img/bg/squarespace-livingroom.jpg';
import SplashCard from '../components/SplashCard/splash-card';

import FcRoutes from '../services/eventService';

let ps;

function Splash(props) {
  console.log('rerendering splash layout');
  console.log('splash - props', props);
  const location = useLocation();
  const [setSidebarMini] = React.useState(false);
  const mainPanel = React.useRef();

  const { getAccessTokenSilently, logout } = useAuth0();
  const savedLoanCard = JSON.parse(localStorage.getItem('selectedLoan'));
  localStorage.setItem('hasVisitedSplashPage', true);

  const getAccessToken = async () => {
    try {
      const token = await getAccessTokenSilently();
      return token;
    } catch (error) {
      return Promise.reject(error);
    }
  };

  React.useEffect(() => {
    if (navigator.platform.indexOf('Win') > -1) {
      document.documentElement.className += ' perfect-scrollbar-on';
      document.documentElement.classList.remove('perfect-scrollbar-off');
      ps = new PerfectScrollbar(mainPanel?.current);
    }
    return function cleanup() {
      if (navigator.platform.indexOf('Win') > -1) {
        ps.destroy();
        document.documentElement.className += ' perfect-scrollbar-off';
        document.documentElement.classList.remove('perfect-scrollbar-on');
      }
    };
  }, []);

  React.useEffect(() => {
    if (document?.documentElement && document?.scrollingElement && mainPanel?.current) {
      document.documentElement.scrollTop = 0;
      document.scrollingElement.scrollTop = 0;
      mainPanel.current.scrollTop = 0;
    }
  }, [location]);

  React.useEffect(async () => {
    try {
      let apiClient = new FcRoutes(await getAccessToken());
      apiClient.patchUser(props.loanId).then((res) => {
        console.log(res);
      });
    } catch (error) {
      console.log(error);
    }
  });

  const getRoutes = (routes) => {
    let matched = routes.map((x) => x.layout + x.path);
    return routes.map((prop, key) => {
      if (prop.collapse) {
        return getRoutes(prop.views);
      }
      if (prop.layout === '/splash') {
        if (props?.user?.app_metadata?.isAdverse) {
          return <Redirect key={key} to='' />;
        }
        if (matched.includes(location.pathname)) {
          return <Route path={prop.layout + prop.path} component={() => prop.component} key={key} />;
        } else {
          console.log('REDIRECT 404');
          return <Redirect key={key} to='' />;
        }
      } else {
        return null;
      }
    });
  };

  const handleMiniClick = () => {
    if (document.body.classList.contains('sidebar-mini')) {
      setSidebarMini(false);
    } else {
      setSidebarMini(true);
    }
    document.body.classList.toggle('sidebar-mini');
  };

  if (props.loanFileErr) {
    return (
      <div className='wrapper main-content-splash'>
        <div ref={mainPanel}>
          <div
            style={{
              display: 'flex',
              flexWrap: 'wrap',
              justifyContent: 'center',
              verticalAlign: 'center',
              marginTop: '50px',
            }}>
            <Card
              style={{
                width: '25rem',
              }}>
              <img alt='Sample' src='https://picsum.photos/300/200' />
              <CardBody>
                <CardTitle tag='h5'>Uh-Oh!</CardTitle>
                <CardSubtitle className='mb-2 text-muted' tag='h6'>
                  Internal Server Error
                </CardSubtitle>
                <CardText>
                  We were unable to access your loan information. Please try logging out and logging back in, or try logging in from a different web
                  browser (Edge, Chrome or Firefox). If the issue persists please contact Loan Depot.
                </CardText>
                <Button
                  className='button blue_style'
                  onClick={() =>
                    logout({
                      returnTo: `${window.location.origin}${process.env.REACT_APP_HOMEPAGE_PATH}`,
                    })
                  }>
                  Return to Borrower Portal Login
                </Button>
              </CardBody>
            </Card>
          </div>
        </div>
      </div>
    );
  }

  const getName = () => {
    if (props.loanFile && props.user) {
      let matchedNamesFromBorrowerList = props.loanFile?.[0]?.borrowers;
      if (matchedNamesFromBorrowerList && matchedNamesFromBorrowerList.length > 0) {
        return matchedNamesFromBorrowerList[0].firstName;
      }
    }
    return '';
  };

  return (
    <div className='wrapper'>
      <div ref={mainPanel}>
        {props.loanFile ? (
          <>
            <SplashNavbar {...props} handleMiniClick={handleMiniClick} />
            <Switch>{getRoutes(routes)}</Switch>
            {
              // we don't want the Footer to be rendered on full screen maps page
              props.location.pathname.indexOf('full-screen-map') !== -1 ? null : <Footer fluid />
            }
            <img src={livingroom} width='100%' height='200px' style={{ objectFit: 'cover', objectPosition: '0 45%' }} />
            <div
              style={{
                position: 'relative',
                bottom: '100px',
                zIndex: '100',
                textAlign: 'center',
                color: 'white',
              }}>
              {props.loanFile ? (
                <h2 style={{ fontWeight: 'bold', marginBottom: '0' }}>Hi {getName()}!</h2>
              ) : (
                <h2 style={{ fontWeight: 'bold', marginBottom: '0' }}>...</h2>
              )}

              <p style={{ fontWeight: 'bold' }}>Welcome to your loan portal.</p>
            </div>
            <div className='container-fluid w-50 font-weight-bold'>
              <p className='text-center' style={{ margin: '0', padding: '0' }}>
                From here you can see live status updates, document upload, and have 24/7 access from any device. You're in complete control with a
                transparent view of your loan process.
              </p>
            </div>
            <div className='container-fluid w-100'>
              <Card
                className='card-plain'
                style={{
                  paddingBottom: '0',
                  marginBottom: '0',
                  paddingTop: '0',
                  marginTop: '0',
                }}>
                <CardBody
                  style={{
                    paddingBottom: '0',
                    marginBottom: '0',
                    paddingTop: '0',
                    marginTop: '0',
                  }}>
                  <Row>
                    <Col lg='3' style={{ height: '290px' }}>
                      <Card className='card-plain'>
                        <CardBody className='text-center'>
                          <SplashCard
                            classList='fa-sharp fa-solid fa-tablet-screen-button fa-fw white_style'
                            message='Access your portal anytime, anywhere from any device.'
                          />
                        </CardBody>
                      </Card>
                    </Col>
                    <Col lg='3' style={{ height: '290px' }}>
                      <Card className='card-plain'>
                        <CardBody className='text-center'>
                          <SplashCard
                            classList='fal fa-file-upload text-white'
                            message='Securely upload documents and easily manage paperwork on the fly.'
                          />
                        </CardBody>
                      </Card>
                    </Col>
                    <Col lg='3' style={{ height: '290px' }}>
                      <Card className='card-plain'>
                        <CardBody className='text-center'>
                          <SplashCard
                            classList='fa-light fa-message-dots text-white'
                            message='Quickly connect with your loan team to communicate and send attachments.'
                          />
                        </CardBody>
                      </Card>
                    </Col>
                    <Col lg='3' style={{ height: '290px' }}>
                      <Card className='card-plain'>
                        <CardBody className='text-center'>
                          <SplashCard
                            classList='fa-light fa-bell text-white'
                            message='Receive notifications via text messages or email based on your profile settings.'
                          />
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </div>
            <div className='container-fluid w-100 text-center'>
              <Link
                to={props.loanFile.length > 1 && !savedLoanCard ? '/multiple/loans' : '/admin/loan-info'}
                onClick={() => localStorage.setItem('hasVisitedSplashPage', true)}>
                - <button className='button blue_style splash_button'>Let's Go</button>
              </Link>
            </div>
          </>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
}

export default Splash;
