import React, { useState } from "react"
import {
  Card,
  CardBody,
  CardText,
  CardTitle,
  Modal,
  ModalBody,
} from "reactstrap"
import FcRoutes from "../services/eventService"
import { useAuth0 } from "@auth0/auth0-react"
import LoadingMsg from "../components/LoadingMsg"
import CodeModal from "../components/CodeModal/CodeModal"
import { useDispatch } from "react-redux"
import { lockApplication, unlockApplication } from "../redux/slices/application"

// Interval to verify eClose information
const INTERVAL_MILISECONDS = 15000

function Closing(props) {
  const dispatch = useDispatch()
  const getAccessToken = async () => {
    const token = await getAccessTokenSilently()
    return token
  }

  const { getAccessTokenSilently } = useAuth0()

  const [clicked, setClicked] = useState(false)
  const [putFrame, setPutFrame] = useState(false)
  const [authenticationCode, setAuthenticationCode] = useState()
  const [showCodeModal, setShowCodeModal] = useState(false)
  const [error, setError] = useState(false)
  const [url, setUrl] = useState("")
  const [isOpen, setIsOpen] = useState(false)
  const [eCloseInfo, setECloseInfo] = useState(null)

  const verifyECloseInformation = async reccurrentInterval => {
    const apiClient = new FcRoutes(await getAccessToken()) // Get api client
    apiClient
      .getUser()
      .then(res => {
        const { user_metadata } = res.data // Get user metadata
        const { eclose } = user_metadata // Get eClose information
        console.log(eclose, "eeeeeclose")
        if (
          (eclose?.ecloseSigningRoomStatus === "error" &&
            eclose?.ecloseSigningRoomStatusDescription) ||
          (eclose?.ecloseSigningRoomStatus === "success" &&
            eclose?.ecloseSigningRoomStatusDescription)
        ) {
          // If eClose information is available
          if (
            eclose.ecloseSigningRoomStatusDescription !==
            "Documents signed successfully"
          ) {
            setIsOpen(true) // Open modal
          }
          setECloseInfo(eclose) // Set eClose information
          clearInterval(reccurrentInterval) // Clear interval
          dispatch(unlockApplication()) // Unlock application
          setPutFrame(false) // Set putFrame to false
          setUrl("") // Set url to empty
          setClicked(false) // Set clicked to false
        }
      })
      .catch(err => {
        setError(true)
        setMyLoading(false)
        setLoanFileErr(true)
      })
  }

  const onClickGetRoom = async e => {
    e.preventDefault()

    // Interval to verify eClose information
    let reccurrentInterval = null

    setClicked(true) // Set clicked to true
    dispatch(lockApplication()) // Lock application
    const apiClient = new FcRoutes(await getAccessToken()) // Get api client
    const savedLoanCard = JSON.parse(localStorage.getItem('selectedLoan'));

    apiClient
      .getToken(savedLoanCard?.loanId)
      .then(resp => {
        console.log(resp?.data)
        setUrl(resp?.data?.url)
        setPutFrame(true)
        reccurrentInterval = setInterval(() => {
          verifyECloseInformation(reccurrentInterval)
        }, INTERVAL_MILISECONDS)
      })
      .catch(error => {
        setError(true)
        setPutFrame(true)
      })
  }

  const Iframe = () => {
    // Iframe component
    setTimeout(() => {
      document.documentElement.scrollTop = document.documentElement.scrollHeight
      document.scrollingElement.scrollTop =
        document.scrollingElement.scrollHeight
      if (props.mainPanel) {
        props.mainPanel.current.scrollTop = props.mainPanel.current.scrollHeight
      }
    }, 3000)

    if (url) {
      return (
        <div className="container-iframe">
          <iframe
            className="display-iframe"
            title="Inline Frame"
            src={url}
            width="600"
            height="600"
            frameBorder="0"
            allowFullScreen
            allow="autoplay; camera; microphone"
          />
        </div>
      )
    }
    return null
  }

  const renderIFrameModal = () => {
    return (
      <Modal
        className="notification-modal"
        isOpen={isOpen}
        toggle={() => setIsOpen(false)}
        modalTransition={{ timeout: 500 }}
      >
        <ModalBody className="p-0">
          <div className="p-3 text-center">
            {eCloseInfo && (
              <>
                <h3 className="text-center p-2 m-0">Information</h3>
                <p className="m-0 mt-3 mb-3">
                  {eCloseInfo?.ecloseSigningRoomStatusDescription || ""}
                </p>
                <button
                  className="btn-primary-force"
                  id="2"
                  onClick={() => setIsOpen(false)}
                >
                  Close
                </button>
              </>
            )}
          </div>
        </ModalBody>
      </Modal>
    )
  }

  if (props.loading) {
    return <LoadingMsg />
  }
  if (!url && clicked && putFrame) {
    return (
      <div className="content">
        <Card className="setSizeW">
          <CardBody>
            <span className="large_purple">
              <i className="fas fa-exclamation-triangle"></i>
            </span>{" "}
            <br />
            <CardText>Uh Oh!</CardText>
            {error ? (
              <CardText>
                We're having a hard time opening the disclosures window. Our
                team will reach out to your shortly to clear this up. For
                further assistance please contact us at{" "}
                <a href="#">heloc@phatwallet.com </a> <br />
              </CardText>
            ) : (
              <CardText>
                Closing Documents are not currently available. Please check back
                later or check with your Loan Officer for assistance.
              </CardText>
            )}
          </CardBody>
        </Card>
      </div>
    )
  }
  return (
    <>
      <div className="content">
        {!clicked && (
          <Card className="setSizeM">
            <CardBody>
              <span className="large_purple">
                <i className="fa-regular fa-circle-exclamation"></i>
              </span>{" "}
              <br />
              <CardTitle>Closing</CardTitle>
              <CardText>
                To get started please click <b>Review Documents</b> below. We'll
                set up a secure signing room for you to review and sign the
                disclosures.
              </CardText>
              {clicked ? (
                <button
                  className="button blue_style"
                  id="1"
                  disabled
                  style={{ color: "grey" }}
                >
                  Review Documents
                </button>
              ) : (
                <button
                  className="button blue_style"
                  id="1"
                  onClick={e => {
                    onClickGetRoom(e)
                  }}
                >
                  Review Documents
                </button>
              )}
            </CardBody>
          </Card>
        )}
        {putFrame ? <Iframe /> : null}
        {!putFrame && clicked && (
          <LoadingMsg type="Splash" message="Hold on while we authenticate." />
        )}
        <CodeModal
          close={() => setShowCodeModal(false)}
          isOpen={showCodeModal}
          authenticationCode={authenticationCode}
        />
        {renderIFrameModal()}
      </div>
    </>
  )
}

export default Closing
