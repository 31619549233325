import React, { useEffect } from "react"
// javascript plugin used to create scrollbars on windows
import PerfectScrollbar from "perfect-scrollbar"
import { Route, Switch, useLocation } from "react-router-dom"
import AdverseView from "../views/Adverse"
import SplashNavbar from "../components/Navbars/SplashNavbar"
import AdverseDocuments from "../views/AdverseDocuments"

function Adverse(props) {
  const location = useLocation()
  const mainPanel = React.useRef()

  useEffect(() => {
    if (navigator.platform.indexOf("Win") > -1) {
      document.documentElement.className += " perfect-scrollbar-on"
      document.documentElement.classList.remove("perfect-scrollbar-off")
      ps = new PerfectScrollbar(mainPanel?.current)
    }
    return function cleanup() {
      if (navigator.platform.indexOf("Win") > -1) {
        ps.destroy()
        document.documentElement.className += " perfect-scrollbar-off"
        document.documentElement.classList.remove("perfect-scrollbar-on")
      }
    }
  }, [])

  React.useEffect(() => {
    if (
      document?.documentElement &&
      document?.scrollingElement &&
      mainPanel?.current
    ) {
      document.documentElement.scrollTop = 0
      document.scrollingElement.scrollTop = 0
      mainPanel.current.scrollTop = 0
    }
  }, [location])

  const getRoutes = () => {
    return (
      <>
        <Route
          exact
          path="/adverse"
          render={() => <AdverseView {...props} />}
        />
        <Route
          path="/adverse/documents"
          render={() => <AdverseDocuments {...props} />}
        />
      </>
    )
  }

  return (
    <div className="wrapper">
      <div ref={mainPanel}>
        <>
          {/* use splashnavbar component since it does not require a nav panel */}
          <SplashNavbar {...props} adverse />
          <Switch>{getRoutes()}</Switch>
        </>
      </div>
    </div>
  )
}

export default Adverse
