import firstCloseLogo from "../assets/img/fc-brand.svg"
import firstCloseIcon from "../assets/img/favicon.svg"
import firstCommunityLogo from "../assets/img/FirstCommunity-Logo.png"
import firstCommunityIcon from "../assets/img/FirstCommunity-Logo-small.png"

export const TEMPLATE_CONFIG_ICEDEMO = {
  lenderId: "1740f826-ac04-4b4f-a5a0-4230deede016",
  configurationId: "123564634d-ac04-4b4f-a5a0-4230deede016",
  configurationVersion: "1.6",
  lenderName: "FirstClose",
  cltvPercentage: 85, //previously known as ltvPercentageLin
  minDrawPercentage: 75,
  minLineAmount: 50000,
  maxLineAmount: 250000,
  globalTheme: {
    primaryColor: "#503CED",
    secondaryColor: "#2F1758",
    gradientColor: "linear-gradient(136.28deg, #2F1758 -4.07%, #5140E9 106.3%)",
    backgroundColor: "#503CED",
    errorColor: "#CE3939",
    logo: firstCloseLogo,
    favicon: firstCloseIcon,
    adaComplianceGreen: "#2F1758",
  },
}

export const TEMPLATE_CONFIG_FIRSTCOMMUNITY = {
  lenderId: "1740f826-ac04-4b4f-a5a0-4230deede016",
  configurationId: "123564634d-ac04-4b4f-a5a0-4230deede016",
  configurationVersion: "1.6",
  lenderName: "FirstCommunity",
  cltvPercentage: 85, //previously known as ltvPercentageLin
  minDrawPercentage: 75,
  minLineAmount: 50000,
  maxLineAmount: 250000,
  globalTheme: {
    primaryColor: "#e03a3e",
    secondaryColor: "#000000",
    backgroundColor: "#e03a3e",
    errorColor: "#CE3939",
    gradientColor: "linear-gradient(136.28deg, #7e4545 -4.07%, #CE3939 106.3%)",
    logo: firstCommunityLogo,
    favicon: firstCommunityIcon,
    adaComplianceGreen: "#2F1758",
    sidebarStyle: "default",
  },
}
