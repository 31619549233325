/*!

=========================================================
* Paper Dashboard PRO React - v1.3.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useEffect, useState } from 'react';
import classnames from 'classnames';
import { useLocation, Link } from 'react-router-dom';
import {
  Collapse,
  NavbarBrand,
  Navbar,
  NavItem,
  NavLink,
  Nav,
  Container,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownItem,
  DropdownMenu,
} from 'reactstrap';
import { useAuth0 } from '@auth0/auth0-react';
import { useSelector } from 'react-redux';
import logo from '../../assets/logos/firstcommunity.svg';

function SplashNavbar(props) {
  const { logout } = useAuth0();
  const [collapseOpen, setCollapseOpen] = useState(false);
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [color, setColor] = useState('navbar-transparent');
  const location = useLocation();
  const [notificationTasks] = React.useState([]);
  const TemplateConfig = useSelector((state) => state.TemplateConfig.globalTheme);

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      window.addEventListener('resize', updateColor);
    }
    return () => {
      isMounted = false;
    };
  });

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      if (window.outerWidth < 993 && document.documentElement.className.indexOf('nav-open') !== -1) {
        document.documentElement.classList.toggle('nav-open');
      }
    }
    return () => {
      isMounted = false;
    };
  }, [location]);
  // function that adds color white/transparent to the navbar on resize (this is for the collapse)
  const updateColor = () => {
    if (window.innerWidth < 993 && collapseOpen) {
      setColor('bg-white');
    } else {
      setColor('navbar-transparent');
    }
  };

  // this function opens and closes the collapse on small devices
  // it also adds navbar-transparent class to the navbar when closed
  // ad bg-white when opened
  const toggleCollapse = () => {
    if (!collapseOpen) {
      setColor('bg-white');
    } else {
      setColor('navbar-transparent');
    }
    setCollapseOpen(!collapseOpen);
  };
  const getName = () => {
    if (props.loanFile && props.user) {
      let matchedNamesFromBorrowerList = props.loanFile?.[0].borrowers;
      if (matchedNamesFromBorrowerList && matchedNamesFromBorrowerList.length > 0) {
        return matchedNamesFromBorrowerList[0].firstName + ' ' + matchedNamesFromBorrowerList[0].lastName;
      }
    }
    return 'Guest';
  };
  return (
    <>
      <Navbar className={classnames('fixed-top', color, 'splash')} expand='lg'>
        <Container fluid>
          <div className='navbar-wrapper'>
            <div
              className={classnames('navbar-toggle', {
                toggled: sidebarOpen,
              })}></div>
            <NavbarBrand href='/' onClick={(e) => e.preventDefault()}>
              <span className='d-none d-md-block'>
                <div className='simple-text logo-normal'>
                  <img src={TemplateConfig?.logoSplashScreen || logo} alt='logo' />
                </div>
              </span>
              <span className='d-block d-md-none'>
                <div className='simple-text logo-normal'>
                  <img src={TemplateConfig?.logoSplashScreen || logo} alt='logo' />
                </div>
              </span>
            </NavbarBrand>
          </div>
          <button
            aria-controls='navigation-index'
            aria-expanded={collapseOpen}
            aria-label='Toggle navigation'
            className='navbar-toggler'
            data-toggle='collapse'
            type='button'
            onClick={toggleCollapse}>
            <span className='navbar-toggler-bar navbar-kebab' />
            <span className='navbar-toggler-bar navbar-kebab' />
            <span className='navbar-toggler-bar navbar-kebab' />
          </button>
          <Collapse className='justify-content-end' navbar isOpen={collapseOpen}>
            <Nav navbar>
              {!props.adverse && (
                <>
                  <UncontrolledDropdown className='' nav>
                    <DropdownToggle aria-haspopup={true} caret color='default' data-toggle='dropdown' id='navbarDropdownMenuLink' nav>
                      <i className='nc-icon nc-bell-55 move-right' />
                      <p>
                        <span className='d-lg-none d-md-block'>&nbsp;&nbsp;&nbsp;Notifications</span>
                      </p>
                      <div
                        className='notification-flag'
                        style={{
                          display: notificationTasks.length > 0 ? 'block' : 'none',
                        }}></div>
                    </DropdownToggle>
                    <DropdownMenu persist aria-labelledby='navbarDropdownMenuLink' right>
                      {notificationTasks.length > 0 ? (
                        notificationTasks.map((task, key) => {
                          return (
                            <Link to='/admin/tasks' key={key} className=''>
                              <DropdownItem className='btn notification-links'>You have a task that needs to be completed.</DropdownItem>
                            </Link>
                          );
                        })
                      ) : (
                        <p style={{ fontSize: '12px', margin: '5px 20px' }}>No notifications at this time.............</p>
                      )}
                    </DropdownMenu>
                  </UncontrolledDropdown>
                </>
              )}
              <UncontrolledDropdown nav style={{ paddingRight: '0' }}>
                <DropdownToggle aria-haspopup={true} color='default' data-toggle='dropdown' id='navbarDropdownMenuLink' nav>
                  <i className='fa-regular fa-circle-question move-left' />
                  <p>
                    <span className='d-lg-none d-md-block'>Account</span>
                  </p>
                </DropdownToggle>
                <DropdownMenu persist aria-labelledby='navbarDropdownMenuLink' right>
                  <div>
                    <p className='fontSize12 navbarInfoText'>
                      Email{' '}
                      <a className='infoContact' href={`mailto:${TemplateConfig?.adminNavbar?.emailContactLink}`}>
                        {TemplateConfig?.adminNavbar?.emailContact}
                      </a>{' '}
                      or call{' '}
                      <a className='infoContact' href={`tel:${TemplateConfig?.adminNavbar?.phoneContactLink || ''}`}>
                        {TemplateConfig?.adminNavbar?.phoneContact}
                      </a>{' '}
                      for assistance
                    </p>
                  </div>
                </DropdownMenu>
              </UncontrolledDropdown>
              <UncontrolledDropdown className='' nav>
                <DropdownToggle aria-haspopup={true} caret color='default' data-toggle='dropdown' id='navbarDropdownMenuLink' nav>
                  {props.loanFile && (
                    <>
                      <i className='fa-regular fa-circle-user move-left' />
                      <p>
                        <span className=' d-md-block'>{getName()}</span>
                      </p>
                    </>
                  )}
                </DropdownToggle>
                <DropdownMenu persist aria-labelledby='navbarDropdownMenuLink' right>
                  <DropdownItem
                    className='move-right btn notification-link'
                    onClick={() => logout({ returnTo: `${window.location.origin}${process.env.REACT_APP_HOMEPAGE_PATH}` })}>
                    <>
                      <i className='fas fa-power-off move-left' />
                      <span>Log out</span>
                    </>
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
            </Nav>
          </Collapse>
        </Container>
      </Navbar>
    </>
  );
}

export default SplashNavbar;
