import React from "react";
import { Col, Row } from "reactstrap"
import { BrowserRouter, Link } from "react-router-dom"

// Components
import SocialMediaIcons from "./components/SocialMediaIcons"

/**adverse action landing page */
function Adverse() {
  return (
    <div className="content adverse-view">
      <Row className="row-cols-1">
        <Col>
          {/* use CTA Message */}
          <p>
            <span className="adverse_body">
              We appreciate your interest in a HELOC with loanDepot. Our team
              has reviewed your application and we are unable to offer you a
              HELOC at this time. Please note, loanDepot does not report that we
              were unable to offer you a HELOC to the credit reporting agencies.
            </span>
          </p>
          <p>
            <span className="adverse_body">
              Please visit our Secure Document Portal, which contains the
              notification of principal reasons why we are unable to move
              forward with your application. In order to access the portal, you
              must verify your identity, create a password, and consent to the
              terms and conditions.
            </span>
          </p>
          <p>
            <span className="adverse_body">
              Thanks again for considering us. Feel free to visit our website,{" "}
              <a
                className="loandepotlink"
                target="_blank"
                href="https://www.loandepot.com"
              >
                www.loandepot.com
              </a>
              , for additional products that may fit your needs.
            </span>
          </p>
        </Col>
        <Col className="mt-4">
          {/* CTA */}
          <BrowserRouter>
            <Link to="/adverse/documents">
              <button className="button blue_style">View Documents</button>
            </Link>
          </BrowserRouter>
        </Col>
      </Row>
      <SocialMediaIcons />
    </div>
  )
}

export default Adverse;
