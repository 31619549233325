import React from "react";

import { useAuth0 } from "@auth0/auth0-react";
import FcRoutes from "../services/eventService";
import ErrorScreen from "./components/Error-Screen";

/**adverse action landing page */
function AdverseDocuments(props) {
  let { user } = props;
  const { getAccessTokenSilently } = useAuth0()

  const Room = () => {
    const [roomLink, setRoomLink] = React.useState(false);
    const [roomInfo, setRoomInfo] = React.useState(null);

    React.useEffect(() => {
      //get room link
      let getRoom = async () => {
        const token = await getAccessTokenSilently();
        let apiClient = new FcRoutes(token);
        let addSigningRoomRequestItem = {
          loanId: 1,
          type: "Initial",
        };
        apiClient
          .addSigningRoom(addSigningRoomRequestItem)
          .then((res) => {
            setRoomInfo(res.data);
            setSpinner(false);
          })
          .catch((err) => {
            console.error(`Loan ID ${props.user?.user_metadata?.loanId}`, err);
            setErrorMsg(true);
            setSpinner(false);
          });
      };

      // getRoom();
      // test value
      //https://pp.smartenotary.com/direct/customsigningroom/NoteSign?invitationCode=276272ba-5a22-45fa-9a18-3bc12e547021&postbackURL=NA&appKey=BT5Ip09kB%2Bs%3D

      setRoomInfo({
        link: user.app_metadata?.adverseUrl,
      });
      setRoomLink(true);
    }, []);

    const Iframe = ({ info }) => {
      const { link } = info;
      if (link) {
        return (
          <div
            dangerouslySetInnerHTML={{
              __html: `<div className="container-iframe" style="width:100%; height:100vh; min-height: 600px"><iframe className="display-iframe" title="Inline Frame" src="${link}" width="100%" height="700px" frameborder="0" allowfullscreen /></div>`,
            }}
          />
        );
      }
      return (
        <ErrorScreen errorMessageLong="We are having issues pulling up your documents.  We apologize for any inconvenience this may have caused.  Please contact <a href='#'>heloc@loandepot.com<</a> for further assistance or try logging out and back in." />
      );
    };

    return (
      <div>
        {roomInfo !== null ? roomLink && <Iframe info={roomInfo} /> : null}
      </div>
    );
  };

  /**get documents on load */

  return (
    <>
      <Room></Room>
    </>
  );
}

export default AdverseDocuments;
