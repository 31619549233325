import React from 'react';
import { ReactComponent as FirstClose } from "../../assets/logos/fc-brand.svg";

const IMAGE_EXTENSIONS_REGEX = /\.(jpg|jpeg|png|gif|bmp|svg|webp)$/i;
const BASE_64_PREFIX = 'data:image/';
const BASE_64_INFIX = 'base64,';

const CustomLogo = ({ param }) => {

  const isImageUrl = url => IMAGE_EXTENSIONS_REGEX.test(url);

  const isBase64Image = imageParam => 
    imageParam.startsWith(BASE_64_PREFIX) && imageParam.includes(BASE_64_INFIX);

  const renderUrlImage = url => <img src={url} alt="logo" />;

  const renderBase64Image = logo => (
    <svg
      width="198"
      height="52"
      viewBox="0 0 198 52"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <rect width="198" height="52" fill="url(#pattern0)" />
      <defs>
        <pattern
          id="pattern0"
          patternContentUnits="objectBoundingBox"
          width="1"
          height="1"
        >
          <use
            xlinkHref="#image0_custom_logo"
            transform="translate(0 -0.0015528) scale(0.00178571 0.00590062)"
          />
        </pattern>
        <image
          id="image0_custom_logo"
          width="560"
          height="170"
          xlinkHref={logo}
        />
      </defs>
    </svg>
  );

  const renderDefault = () => <FirstClose />;

  if (isImageUrl(param)) {
    return renderUrlImage(param);
  }
  
  if (isBase64Image(param)) {
    return renderBase64Image(param);
  }

  return renderDefault();
};

export default CustomLogo;
