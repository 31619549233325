import { configureStore } from "@reduxjs/toolkit";
import applicationSlice from "../../redux/slices/application";
import TemplateConfigSlice from "../../redux/slices/templateConfig";

export const store = configureStore({
  reducer: {
    application: applicationSlice,
    TemplateConfig: TemplateConfigSlice,
  },
});
