import React, { useEffect, useState } from "react";
import FcRoutes from "../../services/eventService";
import { useAuth0 } from "@auth0/auth0-react";

function CompletedZoneComponent(props) {
  const { getAccessTokenSilently } = useAuth0();

  const getAccessToken = async () => {
    const token = await getAccessTokenSilently();
    return token;
  };

  const [filesReady0, setFilesReady0] = useState(null);
  const [filesReady1, setFilesReady1] = useState(null);
  const [filesReady2, setFilesReady2] = useState(null);
  const [file0Err, setFile0Err] = useState(false);
  const [file1Err, setFile1Err] = useState(false);
  const [file2Err, setFile2Err] = useState(false);
  const [spinner, setSpinner] = useState(false);

  const setFilesReady0Instance = (file) => setFilesReady0(file);
  const setFilesReady1Instance = (file) => setFilesReady1(file);
  const setFilesReady2Instance = (file) => setFilesReady2(file);

  const setFile0ErrInstance = (b) => setFile0Err(b);
  const setFile1ErrInstance = (b) => setFile1Err(b);
  const setFile2ErrInstance = (b) => setFile2Err(b);

  useEffect(async () => {
    let isMounted = true;
    if (isMounted && props.task.taskId == props?.currentTaskLoad) {
      setSpinner(true);
      let numberOfDocsChecked = 0;
      let numberOfDocsCheckedRef = [numberOfDocsChecked];
      var apiClient = new FcRoutes(await getAccessToken());
      var documentName0 = `task${props.index}_document0`;
      var documentName1 = `task${props.index}_document1`;
      var documentName2 = `task${props.index}_document2`;
      console.log("fetching docs");
      CreateDocumentUrl(
        apiClient,
        documentName0,
        setFilesReady0Instance,
        setFile0ErrInstance,
        numberOfDocsCheckedRef
      );
      CreateDocumentUrl(
        apiClient,
        documentName1,
        setFilesReady1Instance,
        setFile1ErrInstance,
        numberOfDocsCheckedRef
      );
      CreateDocumentUrl(
        apiClient,
        documentName2,
        setFilesReady2Instance,
        setFile2ErrInstance,
        numberOfDocsCheckedRef
      );
    } else {
      setSpinner(false);
    }
    return () => {
      isMounted = false;
    };
  }, [props?.currentTaskLoad]);

  const CreateDocumentUrl = (
    apiClient,
    documentName,
    filesReadyMethod,
    filesErrMethod,
    numberOfDocsCheckedRef
  ) => {
    apiClient
      .getTaskDocuments(props.loanId, props.task.taskId, documentName)
      .then(async (res) => {
        if (res.data && res.data.length > 0) {
          let doc = res.data[0];
          let { docName, base64 } = doc;
          console.log("found doc", docName);
          let docExtension =
            docName.lastIndexOf(".") > -1
              ? docName.substring(docName.lastIndexOf(".") + 1)
              : "pdf";
          if (docExtension === "pdf") {
            const blob = base64toBlob(base64, "application/pdf");
            const blobUrl = URL.createObjectURL(blob);
            filesReadyMethod(
              <a href={blobUrl} target="_blank" style={{ margin: "0 2.5px" }}>
                {renameDocTo25Chars(docName)}
              </a>
            );
          } else {
            const blob = base64toBlob(base64, `image/${docExtension}`);
            const blobUrl = URL.createObjectURL(blob);
            filesReadyMethod(
              <a href={blobUrl} target="_blank" style={{ margin: "0 2.5px" }}>
                {renameDocTo25Chars(docName)}
              </a>
            );
          }
          // } else {
          //   const datauri = `data:image/${docExtension.substring(
          //     1
          //   )};base64,${base64}`;
          //   const blob = await fetch(datauri).then((r) => r.blob());
          //   const blobUrl = URL.createObjectURL(blob);

          //   filesReadyMethod(
          //     <a href={blobUrl} target="_blank" download={docName} style={{margin: "0 2.5px"}}>
          //       <p>{docName}</p>
          //     </a>
          //   );
          // }
        } else {
          console.log(`could not find doc ${documentName}`);
          filesReadyMethod(null);
        }
        numberOfDocsCheckedRef[0]++;
        if (numberOfDocsCheckedRef[0] === 3) {
          setSpinner(false);
        }
      })
      .catch((err) => {
        console.error(err);
        filesErrMethod(true);
        numberOfDocsCheckedRef[0]++;
        if (numberOfDocsCheckedRef[0] === 3) {
          setSpinner(false);
        }
      });
  };

  function renameDocTo25Chars(docName) {
    if (docName.length > 25) {
      docName =
        docName.substring(0, 15) +
        "..." +
        docName.substring(docName.length - 10, docName.length);
    }
    return docName;
  }

  const base64toBlob = (b64Data, contentType = "", sliceSize = 512) => {
    const byteCharacters = atob(b64Data);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);

      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }

    const blob = new Blob(byteArrays, { type: contentType });
    return blob;
  };

  const thumbs = (
    <>
      {spinner ? (
        <>
          <p>Your documents will be ready shortly...</p>
          <div className="loader"></div>
        </>
      ) : (
        <>
          {props?.currentTaskLoad !== props.task.taskId ? (
            <div className="dragDropContent">
              <br />
              <p>
                To view the documents you submitted, click the View Documents
                button.
              </p>
            </div>
          ) : (
            <div style={{ marginTop: "10px" }}>
              {!file0Err ? (
                <>
                  {filesReady0 && (
                    <div key="file-0" style={{ margin: "20px 0" }}>
                      <div>{filesReady0}</div>
                    </div>
                  )}
                </>
              ) : (
                <>
                  <p>
                    An error occurred while loading the file. Please try again
                    later.
                  </p>
                </>
              )}
              {!file1Err ? (
                <>
                  {filesReady1 && (
                    <div key="file-1" style={{ margin: "20px 0" }}>
                      <div>{filesReady1}</div>
                    </div>
                  )}
                </>
              ) : (
                <>
                  <p>
                    An error occurred while loading the file. Please try again
                    later.
                  </p>
                </>
              )}
              {!file2Err ? (
                <>
                  {filesReady2 && (
                    <div key="file-2" style={{ margin: "20px 0" }}>
                      <div>{filesReady2}</div>
                    </div>
                  )}
                </>
              ) : (
                <>
                  <p>
                    An error occurred while loading the file. Please try again
                    later.
                  </p>
                </>
              )}
            </div>
          )}
        </>
      )}
    </>
  );

  return (
    <section>
      <div
        style={{
          height: "150px",
          borderWidth: 2,
          borderRadius: 2,
          borderColor: "#eeeeee",
          borderStyle: "dashed",
          backgroundColor: "#fafafa",
          color: "black",
          transition: "border .3s ease-in-out",
        }}
      >
        <div style={{ marginTop: "20px" }}>{thumbs}</div>
      </div>
    </section>
  );
}

export default CompletedZoneComponent;
