import React from "react";
import "../../assets/css/font.css";

const SplashCard = (props) => (
  <>
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        backgroundColor: "var(--primary-color)",
        width: "70px",
        height: "70px",
        marginLeft: "auto",
        marginRight: "auto",
        padding: "30px 0",
        marginBottom: "20px",
        borderRadius: "100px",
      }}
    >
      <i
        className={props.classList}
        style={{ fontSize: "25px", fontWeight: "normal" }}
      ></i>
    </div>
    <p style={{ fontFamily: "poppins" }}>{props.message}</p>
  </>
);

export default SplashCard;
