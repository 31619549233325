import React from "react";
import {
  Button,
  Card,
  CardBody,
  CardSubtitle,
  CardText,
  CardTitle,
} from "reactstrap";
import { useAuth0 } from "@auth0/auth0-react";

const ErrorScreen = (props) => {
  const { logout } = useAuth0();
  return (
    <div
      style={{
        display: "flex",
        flexWrap: "wrap",
        justifyContent: "center",
        verticalAlign: "center",
        marginTop: "100px",
      }}
    >
      <Card
        style={{
          width: "25rem",
        }}
      >
        <img alt="Sample" src="https://picsum.photos/300/200" />
        <CardBody>
          <CardTitle tag="h5">Uh-Oh!</CardTitle>
          <CardSubtitle className="mb-2 text-muted" tag="h6">
            Internal Server Error
          </CardSubtitle>
          <CardText>
            {props.errorMessageLong ? (
              <div
                dangerouslySetInnerHTML={{
                  __html: props.errorMessageLong,
                }}
              />
            ) : (
              <>
                We were unable to access your loan information. Please try
                logging out and logging back in, or try logging in from a
                different web browser (Edge, Chrome or Firefox). If the issue
                persists please contact loanDepot.
              </>
            )}
          </CardText>
          <Button
            className="button blue_style"
            onClick={() => {
              localStorage.removeItem('selectedLoan');
              localStorage.removeItem('hasVisitedSplashPage');
              return logout({ returnTo: `${window.location.origin}${process.env.REACT_APP_HOMEPAGE_PATH}` })
            }}
          >
            Return to Borrower Portal Login
          </Button>
        </CardBody>
      </Card>
    </div>
  );
};

export default ErrorScreen;
