import { createSlice } from "@reduxjs/toolkit"

const initialState = {
  isLocked: false,
}

const applicationSlice = createSlice({
  name: "application",
  initialState,
  reducers: {
    lockApplication: (state, action) => {
      state.isLocked = true
    },
    unlockApplication: (state, action) => {
      state.isLocked = false
    },
  },
})

export const { lockApplication, unlockApplication } = applicationSlice.actions

export default applicationSlice.reducer
