import React, { useState, useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import FcRoutes from "../../services/eventService";
import EmptyChatMessage from "./emptyChat";
import ChatMessages from "./chatMessages";
import LoanTeam from "./loanTeam";

const Chat = (props) => {
  const [messages, setMessages] = useState(props.messages);
  const [members, setMembers] = useState(props.loanTeam);
  const [show, setShow] = useState(false);
  const [option, setOption] = useState("messages");
  const [loading, setLoading] = useState(false);
  const { getAccessTokenSilently } = useAuth0();

  const getAccessToken = async () => {
    const token = await getAccessTokenSilently();
    return token;
  };

  useEffect(() => {
    setMessages(props.messages);
  }, [props.messages]);

  useEffect(() => {
    setMembers(props.loanTeam);
  }, [props.loanTeam]);

  useEffect(() => {
    if (show) setAllMessagesAsRead();
  }, [props.messageCount]);

  const renderMessagesContent = () => {
    let choice = "messages";
    if (option === "loanteam") choice = "loanteam";
    else if (messages.length <= 0) {
      choice = "empty";
    }

    switch (choice) {
      case "empty":
        return <EmptyChatMessage />;
      case "loanteam":
        return <LoanTeam members={members} />;
      default:
        return <ChatMessages messages={messages} />;
    }
  };

  const submit = async (event) => {
    event.preventDefault();
    const form = event.target;
    const newMessages = [...messages];

    const apiClient = new FcRoutes(await getAccessToken());
    const payload = {
      text: form.inputMessage.value,
      loanId: props.user.user_metadata.loanId,
    };

    setLoading(true);

    apiClient
      .postMessage(payload)
      .then((resp) => {
        newMessages.push({
          text: payload.text,
          createdByType: "consumer",
          createdOn: new Date(),
        });
        setMessages(newMessages);
        form.inputMessage.value = "";
      })
      .catch((err) => console.error(err))
      .finally(() => {
        setLoading(false);
      });
  };

  const setAllMessagesAsRead = () => {
    if (props.messageCount > 0 && props.setMessageCount) {
      localStorage.setItem("messages", JSON.stringify(props.messages));
      props.setMessageCount(0);
    }
  };

  const getFirstName = () => {
    if (props.loanFile && props.user) {
      var matchedNamesFromBorrowerList = props.loanFile.borrowers.filter(
        (borrower) =>
          (!borrower.isPrimary &&
            props.user.user_metadata.borrowerType == "COBORROWER") ||
          (borrower.isPrimary &&
            props.user.user_metadata.borrowerType == "BORROWER")
      );
      if (
        matchedNamesFromBorrowerList &&
        matchedNamesFromBorrowerList.length > 0
      ) {
        return matchedNamesFromBorrowerList[0].borrowerFirstName;
      }
    }
    return "Guest";
  };

  return (
    <div className="borrower-portal-chat">
      <div className="borrower-portal-chat-container">
        {show && (
          <div className="chat-container">
            <div className="chat-title">
              <div className="chat-close" onClick={() => setShow(false)}>
                <i className="fa-solid fa-circle-xmark"></i>
              </div>
              <div className="chat-title-content">
                <h1>Hi {getFirstName()}</h1>
                <p>Ask our Loan Team for help, they'll</p>
                <p>get back to you as soon as they can!</p>
              </div>
            </div>
            <div className="chat-tabs">
              <div className="chat-tab">
                <span
                  className={`tab ${option === "messages" && "active"}`}
                  onClick={() => setOption("messages")}
                >
                  <span
                    className="chat-refresh"
                    onClick={() => !props.loading && /*refreshMessages()*/ null}
                  >
                    <i
                      className={`fa-solid fa-arrows-rotate ${
                        props.loading && "fa-spin"
                      }`}
                    ></i>
                  </span>
                  Messages
                </span>
              </div>
              <div className="chat-tab">
                <span
                  className={`tab ${option === "loanteam" && "active"}`}
                  onClick={() => setOption("loanteam")}
                >
                  Loan Team
                </span>
              </div>
            </div>
            <div className="chat-messages-container">
              <div className="chat-messages">{renderMessagesContent()}</div>
              <form className="chat-input" onSubmit={event => submit(event)}>
                <input
                  id="inputMessage"
                  placeholder="send a new message"
                  required
                />
                <button>
                  <i
                    className={
                      loading
                        ? "fa-spin fa-duotone fa-spinner-third"
                        : "fa-regular fa-paper-plane-top"
                    }
                  ></i>
                </button>
              </form>
            </div>
          </div>
        )}
      </div>
    </div>
  )
};

export default Chat;
