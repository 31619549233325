import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';
import { useSelector } from 'react-redux';
import SafeHTML from './../../utils/safeHTML';

const MaintenanceBanner = () => {
    const TemplateConfig = useSelector((state) => state.TemplateConfig);
    const bannerVerbiage = TemplateConfig?.features?.isMaintenanceWindow?.bannerVerbiage || '<b>Site Maintenance Notice:</b> Our website is temporarily down for maintenance.';
    const bannerBackgroundColor = TemplateConfig?.features?.isMaintenanceWindow?.bannerBackgroundColor || "#FDCB1B";
    const bannerOpacityPercentage = TemplateConfig?.features?.isMaintenanceWindow?.bannerOpacityPercentage || "70";

   function hexToRgba(hex, opacity) {
      // remove hash char
      hex = hex.replace(/^#/, '');
      let r, g, b;
      // remove non numeric chars
      let sanitizedOpacity = opacity?.replace(/[^0-9]/g, '') || 70;
      let calculatedOpacity = 0.7;
      try {
        if (hex.length === 3) {
          //fc0
          r = parseInt(hex[0] + hex[0], 16);
          g = parseInt(hex[1] + hex[1], 16);
          b = parseInt(hex[2] + hex[2], 16);
        } else if (hex.length === 6) {
          //ffcc00
          r = parseInt(hex.substring(0, 2), 16);
          g = parseInt(hex.substring(2, 4), 16);
          b = parseInt(hex.substring(4, 6), 16);
        } else {
          throw new Error('Invalid HEX color.');
        }
      } catch (e) {
        const defaultHex = "FDCB1B";
        r = parseInt(defaultHex.substring(0, 2), 16);
        g = parseInt(defaultHex.substring(2, 4), 16);
        b = parseInt(defaultHex.substring(4, 6), 16);
      }
      // validate sanitizedOpacity
      try {
        if (sanitizedOpacity >= 0 && sanitizedOpacity <= 100) {
          calculatedOpacity = sanitizedOpacity / 100;
        }
      } catch (e) { }
      document.documentElement.style.setProperty("--banner-bk-RR", r);
      document.documentElement.style.setProperty("--banner-bk-GG", g);
      document.documentElement.style.setProperty("--banner-bk-BB", b);
      document.documentElement.style.setProperty("--banner-bk-opacity", calculatedOpacity);
    }

    useEffect(() => {
        hexToRgba(bannerBackgroundColor, bannerOpacityPercentage);
        const container = document.createElement('div');
        ReactDOM.render(<SafeHTML tag='p' html={bannerVerbiage} /> , container );
        let divMaintenance = document.createElement('div');
        divMaintenance.id = "maintenance-banner";
        divMaintenance.className = "maintenance-banner";
        divMaintenance.appendChild(container);
        document.body.appendChild(divMaintenance);

        return () => {
            document.body.removeChild(divMaintenance);
        };
    }, [bannerBackgroundColor, bannerOpacityPercentage, bannerVerbiage]);

    return null;
};

export default MaintenanceBanner;