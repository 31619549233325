import PlusButton from "../../components/plus-button";
import React, { useEffect, useMemo, useState } from "react";
import { useDropzone } from "react-dropzone";
import { UncontrolledTooltip } from "reactstrap";
import FcRoutes from "../../services/eventService";
import { useAuth0 } from "@auth0/auth0-react";

const baseStyle = {
  height: "150px",
  borderWidth: 2,
  borderRadius: 2,
  borderColor: "#eeeeee",
  borderStyle: "dashed",
  backgroundColor: "#fafafa",
  color: "black",
  transition: "border .3s ease-in-out",
};

const activeStyle = {
  borderColor: "#2196f3",
};

const acceptStyle = {
  borderColor: "#00e676",
};

const rejectStyle = {
  borderColor: "#ff1744",
};

function DropzoneComponent(props) {
  const { getAccessTokenSilently } = useAuth0();

  const getAccessToken = async () => {
    const token = await getAccessTokenSilently();
    return token;
  };

  const [files, setFiles] = useState([]);
  const [errorMessage, setErrorMessage] = useState(false);
  const [filesReady, setFilesReady] = useState([]);
  const [disableDropzone, setDisableDropzone] = useState(false);
  const dragDropContent = (
    <div className="dragDropContent">
      <p>
        Search to +add documents
        <br />
        <strong>or drag and drop here.</strong>
      </p>
    </div>
  );
  const [statusText, setStatusText] = useState("");
  const acceptedFileTypes =
    "application/pdf,application/x-pdf,application/x-bzpdf,application/x-gzpdf,image/png,image/gif,image/jpeg,image/jpg";
  const acceptedFileTypesObj = {
    "application/pdf": [".pdf"],
    "application/x-pdf": [".pdf"],
    "application/x-bzpdf": [".pdf"],
    "application/x-gzpdf": [".pdf"],
    "image/*": [".png", ".gif", ".jpeg", ".jpg"],
  };
  const [droppedFiles, setDroppedFiles] = useState([]);
  const [uploadProgresses, setUploadProgresses] = useState([0, 0, 0]);
  const defaultErrorMessage =
    "Max 3 PDF or image files (45MB total) can be uploaded";
  const [errorText, setErrorText] = useState(defaultErrorMessage);
  const [errorColor, setErrorColor] = useState("black");
  const [spinner, setSpinner] = useState(false);
  const [disableSubmitForever, setDisableSubmitForever] = useState(false);

  const getDistinctFiles = (selectedFiles) => {
    let acceptedFiles = droppedFiles;
    let errors = [];
    if (selectedFiles) {
      for (
        var i = 0, filesAdded = droppedFiles.length;
        i < selectedFiles.length;
        i++
      ) {
        if (
          acceptedFileTypes.includes(selectedFiles[i].type) &&
          filesAdded < 3
        ) {
          if (
            !acceptedFiles.map((x) => x.name).includes(selectedFiles[i].name)
          ) {
            acceptedFiles.push(selectedFiles[i]);
            filesAdded++;
          }
        } else {
          errors.push(selectedFiles[i]);
        }
      }
    }
    return [acceptedFiles, errors];
  };

  const onDrop = (accepted, unaccepted) => {
    var selectedFiles = [...accepted, ...unaccepted];
    let getDistinctFilesObj = getDistinctFiles(selectedFiles);
    let acceptedFiles = getDistinctFilesObj[0];
    let errors = getDistinctFilesObj[1];
    parseFiles(acceptedFiles);
    handleErrors(errors, acceptedFiles);
  };

  const uploadUsingExplorer = (selectedFiles) => {
    let getDistinctFilesObj = getDistinctFiles(selectedFiles);
    let acceptedFiles = getDistinctFilesObj[0];
    let errors = getDistinctFilesObj[1];
    parseFiles(acceptedFiles);
    handleErrors(errors, acceptedFiles);
  };

  const handleErrors = (errors, acceptedFiles) => {
    if (acceptedFiles.length > 3 || errors?.length > 0) {
      setErrorColor("red");
      setErrorText(defaultErrorMessage);
    }
    if (errors?.length === 0) {
      setErrorColor("black");
      setErrorText(defaultErrorMessage);
    }
  };

  const parseOneFile = (acceptedFiles, i, parseFilesObj) => {
    // var parseFilesObj = [progresses, total, loaded];
    if (acceptedFiles.length > i) {
      const bytesToMegaBytes = (bytes) => {
        let size = bytes / 1024 ** 2;
        return Math.round(size * 100) / 100;
      };
      let fileSizeMB = bytesToMegaBytes(acceptedFiles[i].size);
      if (fileSizeMB <= 5.4) {
        let fileReader = new FileReader();
        fileReader.readAsBinaryString(acceptedFiles[i]);

        fileReader.onprogress = function (data) {
          if (data.lengthComputable) {
            var progress = 0;
            // safe divide
            if (data.total > 0) {
              progress = parseInt((data.loaded / data.total) * 100, 10);
            } else {
              progress = 100; // when the file is 0 KB, set progress to 100% because there is nothing to upload.
            }
            parseFilesObj[0][i] = progress;
            setUploadProgresses(parseFilesObj[0]);
          }
        };

        fileReader.onloadend = function () {
          parseFilesObj[2]++;

          if (parseFilesObj[2] == parseFilesObj[1]) {
            onAllFilesLoaded(acceptedFiles);
          }
        };
      }
    }
  };

  const parseFiles = (acceptedFiles) => {
    if (acceptedFiles != null) {
      setDroppedFiles(acceptedFiles);
      var progresses = [0, 0, 0];
      var total = acceptedFiles.length;
      var loaded = 0;
      var parseFilesObj = [progresses, total, loaded];

      parseOneFile(acceptedFiles, 0, parseFilesObj);
      parseOneFile(acceptedFiles, 1, parseFilesObj);
      parseOneFile(acceptedFiles, 2, parseFilesObj);
    }
  };

  const onAllFilesLoaded = (files) => {
    setUploadProgresses([100, 100, 100]);
    setDroppedFiles([...files]);
    setFilesReady([...files]);
  };

  useEffect(() => {
    if (props.task.isInProgress === "true") {
      setDisableDropzone(true);
      setStatusText("Documents successfully uploaded.");
    } else if (disableDropzone) {
      setDisableDropzone(false);
      setStatusText("");
    }
  }, [files, filesReady, errorMessage, props.task]);

  const removeDroppedFile = (fileToRemove) => {
    setErrorColor("black");
    setErrorText(defaultErrorMessage);

    // drop the file
    setFilesReady([]);
    setDroppedFiles(
      droppedFiles.filter((file) => file.name !== fileToRemove.name)
    );
    setUploadProgresses([0, 0, 0]);
    parseFiles(droppedFiles.filter((file) => file.name !== fileToRemove.name));
  };

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
  } = useDropzone({
    onDrop,
    disabled: disableDropzone,
    noClick: true,
    accept: acceptedFileTypesObj,
  });

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isDragActive ? activeStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isDragActive, isDragReject, isDragAccept]
  );

  const thumbs = droppedFiles.map((file, i) => (
    <div key={`${file.name}-${i}`} className="parent-progress-bar-holder">
      <div className="parent-progress-bar-holder-child-one">
        <p id={getFileId(file.name) + i} style={{ margin: 0 }}>
          <span>{displayFileName(file.name)}</span>&nbsp;
          <UncontrolledTooltip
            placement="right"
            target={getFileId(file.name) + i}
            delay={250}
            style={{ zIndex: "2" }}
          >
            {file.name}
          </UncontrolledTooltip>
          <span>({sizeItGood(file.size)})</span>
        </p>
        <div style={{ width: "100%", display: "flex", flexWrap: "nowrap" }}>
          <div
            style={{
              width: uploadProgresses[i] + "%",
              height: "5px",
              backgroundColor: "blue",
            }}
          ></div>
          <div
            style={{
              width: 100 - uploadProgresses[i] + "%",
              height: "5px",
              backgroundColor: "grey",
            }}
          ></div>
        </div>
      </div>
      <button
        className="preClosingButton"
        style={{ zIndex: "1", cursor: "default" }}
      >
        {uploadProgresses[i] === 100 ? (
          <i
            className="fa-light fa-circle-check fa-xl"
            style={{
              color: "green",
              marginRight: "10px",
            }}
          ></i>
        ) : (
          <i
            className="fa-solid fa-circle-ellipsis fa-xl"
            style={{
              color: "grey",
              marginRight: "10px",
            }}
          ></i>
        )}
      </button>
      <button
        className="preClosingButton"
        onClick={() => removeDroppedFile(file)}
        style={{ zIndex: "1" }}
      >
        <i className="fa-light fa-circle-xmark fa-xl"></i>
      </button>
    </div>
  ));

  function sizeItGood(charsInFile) {
    // 1 kB = 1024 characters
    // 1 MB = 1024 kB
    // 1 GB = 1024 MB
    // 1 TB = 1024 GB
    var kbs = charsInFile / 1024;
    var kbsFixed = Math.floor(kbs * 100) / 100;
    if (kbs < 1024) return kbsFixed + " KB";
    var mbs = kbs / 1024;
    var mbsFixed = Math.floor(mbs * 100) / 100;
    if (mbs < 1024) return mbsFixed + " MB";
    var gbs = mbs / 1024;
    var gbsFixed = Math.floor(gbs * 100) / 100;
    if (gbs < 1024) return gbsFixed + " GB";
    var tbs = gbs / 1024;
    var tbsFixed = Math.floor(tbs * 100) / 100;
    return tbsFixed + " TB";
  }

  function displayFileName(str) {
    if (str.length > 20) {
      var extensionInd = str.lastIndexOf(".");
      var extension = str.substring(extensionInd);
      return str.substr(0, 15) + "..." + extension;
    }
    return str;
  }

  function getFileId(str) {
    str = str.replaceAll(/\s+/g, ""); // removes all spaces
    str = str.replaceAll(".", "");
    str = str.replaceAll("/", "");
    str = str.replaceAll("-", "");
    str = str.replaceAll(/[^a-zA-Z0-9]/gi, "");
    for (var i = 0; i < str.length - 1; i++) {
      if (str.substring(i, i + 1).match(/[0-9]/gi)) {
        str = str.replace(/[0-9]/, " ");
      } else {
        break;
      }
    }
    str = str.replaceAll(/\s+/g, ""); // removes all spaces
    if (str.length === 0) {
      str += String.fromCharCode(i);
    }
    return str;
  }

  const upload = () => {
    setDisableSubmitForever(true);
    setSpinner(true);
    console.log(filesReady);
    setDisableDropzone(true);
    let fileUploadSuccessful = true;
    for (var i = 0; i < filesReady.length; i++) {
      var reader = new FileReader();
      let pdfFile = filesReady[i];
      reader.readAsDataURL(pdfFile);
      // const base64String = btoa(String.fromCharCode(...new Uint8Array(arrayBuffer)));
      var docIndex = 0;
      var processedCount = 0;
      reader.onloadend = async (e) => {
        var contents =
          reader.result?.indexOf("base64,") > -1
            ? reader.result.substring(reader.result.indexOf("base64,") + 7)
            : "";
        var fileType = "";
        if (pdfFile.name.lastIndexOf(".") > -1) {
          fileType = pdfFile.name.substring(pdfFile.name.lastIndexOf("."));
        } else {
          fileType = ".pdf";
        }
        var f = {
          taskId: props.task.taskId,
          documentName: `task${props.index}_document${docIndex}${fileType}`,
          loanId: props.props.user.user_metadata.loanId,
          fileContent:
            e.target.result.split(",")[e.target.result.split(",").length - 1],
        };
        console.log("upload this", f);
        var tokenId = await getAccessToken();
        var apiClient = new FcRoutes(tokenId);
        docIndex++;
        apiClient
          .addDocument(f)
          .then((data) => {
            console.log(data);
            setStatusText("Documents successfully uploaded.");
            setDisableDropzone(true);
            setSpinner(false);

            //Call API to Complete the task
            const patchTaskDto = {
              taskId: props.task.taskId,
              loanId: props.props.user.user_metadata.loanId,
            };
            processedCount++;
            if (processedCount === filesReady.length && fileUploadSuccessful) {
              apiClient
                .patchTask(patchTaskDto)
                .then((result) => {
                  console.log(result);
                  //Update Auth0 Tasks In Progress metadata
                  //Make a copy of app_metadata tasksInProgress
                  let tasksInProgressArray = [];
                  if (
                    props.props.user.app_metadata &&
                    props.props.user.app_metadata.tasksInProgress
                  ) {
                    tasksInProgressArray = JSON.parse(
                      JSON.stringify(
                        props.props.user.app_metadata.tasksInProgress
                      )
                    );
                  }
                  //Mark "completed" as in progress
                  const arrayIndex = tasksInProgressArray.findIndex(
                    (item) => item.taskId === patchTaskDto.taskId
                  );
                  const inProgressTaskExists = tasksInProgressArray.some(
                    (item) => item.taskId === patchTaskDto.taskId
                  );
                  if (arrayIndex < 0) {
                    tasksInProgressArray.push({
                      taskId: patchTaskDto.taskId,
                      taskIndex: props.index,
                      taskStarted: new Date().toJSON(),
                    });
                  } else {
                    tasksInProgressArray[arrayIndex].taskStarted =
                      new Date().toJSON();
                  }
                  const appMetaData = {
                    app_metadata: {
                      tasksInProgress: tasksInProgressArray,
                    },
                  };
                  /*apiClient
                    .patchUser(appMetaData)
                    .then((result) => {
                      console.log(result);
                    })
                    .catch((err) => {
                      console.log(err);
                      console.log("message", err.message);
                    });*/
                })
                .catch((err) => {
                  console.log(err);
                  console.log("message", err.message);
                });
            }
          })
          .catch((err) => {
            console.log(err);
            console.log("message", err.message);
            setStatusText(err.message);
            fileUploadSuccessful = false;
            setDisableDropzone(false);
            setErrorMessage(true);
          });
      };
    }

    setFiles([]);
    setFilesReady([]);
  };

  return (
    <section>
      <div
        {...getRootProps({
          style,
        })}
      >
        <input {...getInputProps()} />
        {!disableDropzone && !spinner && !disableSubmitForever && (
          <PlusButton
            accept={acceptedFileTypes}
            onChange={(e) => uploadUsingExplorer(e.target.files)}
            for={`file${props.index}`}
          />
        )}
        {statusText}
        {spinner ? (
          <>
            <div className="loader"></div>
            <p>Documents are being sent to your lending team...</p>
          </>
        ) : (
          <>
            {disableSubmitForever ? (
              <>
                <div>
                  <br />
                  <br />
                  <p>
                    Your files are being processed and will be available for
                    viewing shortly.
                  </p>
                </div>
              </>
            ) : (
              <>
                {!disableDropzone ? (
                  droppedFiles.length > 0 ? (
                    <>{thumbs}</>
                  ) : (
                    <>{dragDropContent}</>
                  )
                ) : (
                  <>
                    <div>
                      <br />
                      <br />
                      <p>
                        Your files are being processed and will be available for
                        viewing shortly.
                      </p>
                    </div>
                  </>
                )}
              </>
            )}
          </>
        )}
      </div>
      <p style={{ color: errorColor }}>{errorText}</p>
      <div>
        {!disableSubmitForever ? (
          <button
            className="btn btn-primary btn-block w-100"
            style={{
              borderRadius: "10px",
              padding: "15px",
            }}
            onClick={() => upload()}
            disabled={
              filesReady.length === 0 &&
              uploadProgresses[0] != 100 &&
              uploadProgresses[1] != 100 &&
              uploadProgresses[2] != 100
            }
          >
            Submit Documents
          </button>
        ) : (
          <button
            className="btn btn-primary btn-block w-100"
            style={{
              borderRadius: "10px",
              padding: "15px",
            }}
            disabled={true}
          >
            Submit Documents
          </button>
        )}
      </div>
    </section>
  );
}

export default DropzoneComponent;
