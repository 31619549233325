import { ReactComponent as FirstClose } from "./fc-brand.svg";
import { ReactComponent as FirstCommunity } from "./firstcommunity.svg";
import CustomLogo from '../../components/CustomLogo';

const Logo = ({ option }) => {
  switch (option.toLowerCase()) {
    case "firstclose":
      return <FirstClose />;
    case "firstcommunity":
      return <FirstCommunity />;
    default:
      return <CustomLogo param={option} />;
  }
};

export default Logo;
