import React, { useEffect, useState } from 'react';
import { AuthWrapper } from './components/AuthWrapper/AuthWrapper';
import ReactDOM from 'react-dom';
import App from './App';
import { Provider, useDispatch } from 'react-redux';
import { store } from './config/redux/store';
import Helmet from 'react-helmet';
import FcRoutes from 'services/eventService';
import LoadingMsg from 'components/LoadingMsg';
import MaintenanceBanner from './components/Navbars/MaintenanceBanner';

const TemplateConfigProvider = ({ children }) => {
  const [templateConfig, setTemplateConfig] = useState(null);
  const [loadingConfig, setLoadingConfig] = useState(true);

  const [isMaintenanceWindow, setIsMaintenanceWindow] = React.useState(false);
  const dispatch = useDispatch();

  const getInitialData = async () => {
    let apiClient = new FcRoutes();
    try {
      setLoadingConfig(true);
      apiClient.getTemplateConfig().then((res) => {
        setTemplateConfig(res.data);
        setLoadingConfig(false);
      });
    } catch (error) {
      console.error(101, error);
      setLoadingConfig(false);
    }
  };

  useEffect(() => {
    if (templateConfig?.globalTheme) {
      setGlobalStyle();
    }
  }, [templateConfig]);

  useEffect(() => {
    if (
      !isMaintenanceWindow &&
      templateConfig?.features?.isMaintenanceWindow != null &&
      (
        templateConfig?.features?.isMaintenanceWindow === true ||
        typeof templateConfig?.features?.isMaintenanceWindow === 'object'
      )
    ) {
      setIsMaintenanceWindow(true);
    }
  }, [templateConfig?.features?.isMaintenanceWindow]);

  const setGlobalStyle = () => {
    document.documentElement.style.setProperty('--primary-color', templateConfig.globalTheme.primaryColor);
    document.documentElement.style.setProperty('--gradient-color', templateConfig.globalTheme.gradientColor);
    document.documentElement.style.setProperty('--secondary-color', templateConfig.globalTheme.secondaryColor);
    document.documentElement.style.setProperty('--background-color', templateConfig.globalTheme.backgroundColor);
    if (templateConfig?.pages?.loans?.loanCardHoverColor) {
      document.documentElement.style.setProperty('--primary-color-hover', templateConfig.pages.loans.loanCardHoverColor);
    } else {
      document.documentElement.style.setProperty('--primary-color-hover', '#381B64');
    }
    document.documentElement.style.setProperty('--primary-color-shadow', templateConfig.globalTheme.primaryColorShadow);
    document.documentElement.style.setProperty('--secondary-color-shadow', templateConfig.globalTheme.secondaryColorShadow);
    const payload = {
      ...templateConfig,
    };
    document.documentElement.style.setProperty('--main-content-color', templateConfig?.pages?.loanInfo?.mainContent?.background ?? '#f9f9fa');
    document.documentElement.style.setProperty(
      '--background-sidebar-color',
      templateConfig?.pages?.loanInfo?.sideBar?.background ?? templateConfig.globalTheme.gradientColor,
    );
    document.documentElement.style.setProperty('--nav-bar-background', templateConfig?.pages?.loanInfo?.navBar?.background ?? '#FFFFFF');
    document.documentElement.style.setProperty('--nav-splash-background', templateConfig?.pages?.splash?.navBar?.background ?? '#FFFFFF');
    document.documentElement.style.setProperty('--main-splash-background', templateConfig?.pages?.splash?.mainContent?.background ?? '#f9f9fa');

    dispatch({
      type: 'TemplateConfig/setTemplateConfig',
      payload: payload,
    });
  };

  useEffect(() => {
    let gettingData = true;
    if (gettingData) getInitialData();
    return () => {
      gettingData = false;
    };
  }, []);

  if (loadingConfig) {
    return <LoadingMsg type='Splash' message={'Loading configuration'} />;
  }

  return (
    <>
      <Helmet>
        <link id='favicon' rel='icon' type='image/png' href={templateConfig?.globalTheme?.favicon} data-react-helmet='true' />
        <title>{templateConfig?.globalTheme.lenderName}</title>
      </Helmet>
      {isMaintenanceWindow && <MaintenanceBanner />}
      {children}
    </>
  );
};

ReactDOM.render(
  <Provider store={store}>
    <TemplateConfigProvider>
      <AuthWrapper>
        <App />
      </AuthWrapper>
    </TemplateConfigProvider>
  </Provider>,
  document.getElementById('root'),
);
