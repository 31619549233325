/*!

=========================================================
* Paper Dashboard PRO React - v1.3.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import axios from "axios";

import { Card, CardBody, CardText, Spinner } from "reactstrap"
import FcRoutes from "../services/eventService"

import { useAuth0 } from "@auth0/auth0-react"

let apiClient = null
let interval = null

function Verification(props) {
  const VerificationStatus = {
    PENDING: 1,
    STARTED: 2,
    RETRY: 3,
    ACCEPTED: 4,
    FAILED: 5,
  }

  //These are the known event types from the webhook server from socure
  const EventStatus = {
    WAITING_FOR_USER_TO_REDIRECT: "WAITING_FOR_USER_TO_REDIRECT",
    APP_OPENED: "APP_OPENED",
    DOCUMENT_FRONT_UPLOADED: "DOCUMENT_FRONT_UPLOADED",
    DOCUMENT_BACK_UPLOADED: "DOCUMENT_BACK_UPLOADED",
    DOCUMENT_SELFIE_UPLOADED: "DOCUMENT_BACK_UPLOADED",
    DOCUMENTS_UPLOADED: "DOCUMENTS_UPLOADED",
    VERIFICATION_COMPLETED: "VERIFICATION_COMPLETED",
    RESUBMIT: "RESUBMIT",
    SESSION_EXPIRED: "SESSION_EXPIRED",
    SESSION_COMPLETE: "SESSION_COMPLETE",
  }

  const VerificationDecision = {
    REJECT: "reject",
    ACCEPT: "accept",
    REVIEW: "review",
    REFER: "refer",
    RESUBMIT: "resubmit",
  }

  const { identityVerificationId, setIdentityVerificationId, setIsFraud } =
    props
  const [idVerification, setIdVerification] = React.useState({
    eventId: identityVerificationId,
  })
  const [spinner, setSpinner] = React.useState(false)
  const [status, setStatus] = React.useState(
    identityVerificationId
      ? VerificationStatus.STARTED
      : VerificationStatus.PENDING
  )
  const [statusUpdated, setStatusUpdate] = React.useState(new Date())
  const { getAccessTokenSilently } = useAuth0()

  const getAccessToken = async () => {
    const token = await getAccessTokenSilently()
    return token
  }

  const onClickGetVerification = async e => {
    e.preventDefault()
    if (!props.idErr) {
      setSpinner(true)
      let token = await getAccessToken()
      if (token && props.idItem) {
        apiClient = new FcRoutes(token)
        apiClient
          .addIdentity(props.idItem)
          .then(res => {
            const { eventId, status } = res.data.data
            setStatus(VerificationStatus.STARTED)
            setIdentityVerificationId(eventId)
            setIdVerification({ eventId, status })
            setSpinner(false)
          })
          .catch(err => {
            if (!props.idItem) {
              console.error("Could not locate ID item", err)
            }
            if (
              !props.idItem?.borrower.borrowerPrimaryPhone ||
              props.idItem.borrower.borrowerPrimaryPhone.trim().length <= 9
            ) {
              console.error(
                "Phone number not provided or invalid.  Please double-check your loan application that you provided a US phone number",
                err
              )
            }
            setSpinner(false)
            setStatus(VerificationStatus.FAILED)
          })
      }
    } else {
      setStatus(VerificationStatus.FAILED)
    }
  }

  //TODO: may need to add a general loading state on initial load if there is an existing verification ID
  const determineLayoutForStatus = () => {
    switch (status) {
      case VerificationStatus.STARTED:
      case VerificationStatus.RETRY:
        return StartedLayout()
      case VerificationStatus.ACCEPTED:
        return AcceptedLayout()
      case VerificationStatus.FAILED:
        return FailureLayout()
      default:
        return PendingLayout()
    }
  }

  const determineStatusFromDecision = decision => {
    switch (decision.toLowerCase()) {
      case VerificationDecision.ACCEPT:
        setStatus(VerificationStatus.ACCEPTED)
        return
      case VerificationDecision.REJECT:
      case VerificationDecision.REVIEW:
        setIsFraud(true) //TODO: this will set the App isFraud to true and can be used to force the Fraud layout
        setStatus(VerificationStatus.FAILED)
        return
      default: //if not accept, review, reject, enable retry
        setStatus(VerificationStatus.RETRY)
        return
    }
  }

  const determineStatusFromEvent = event => {
    switch (event.eventType) {
      case EventStatus.VERIFICATION_COMPLETED:
        clearInterval(interval)
        setStatusUpdate(new Date(event.updatedAt))
        determineStatusFromDecision(event.decision)
        return
      case EventStatus.RESUBMIT:
      case EventStatus.SESSION_EXPIRED:
        setStatus(VerificationStatus.RETRY)
        return
      default:
        //don't care about other EventStatuses for now
        return
    }
  }

  let refreshInterval = 60000 //TODO: do we want to turn this into an env variable?

  const fetchEventData = eventId => {
    axios
      .get(
        `${process.env.REACT_APP_EXPERIENCE_WEBHOOK_URL}/api/v1/socure/${eventId}`
      )
      .then(res => {
        setSpinner(false)
        let resDataLength = res.data.length

        while (resDataLength--) {
          console.log(res.data[resDataLength])
          determineStatusFromEvent(res.data[resDataLength])
        }
      })
      .catch(err => {
        //TODO: do we want this to be a failure, different message, or retry?
        console.log(err)
        setStatus(VerificationStatus.FAILED)
      })
  }

  React.useEffect(() => {
    if (idVerification.eventId) {
      setSpinner(true)
      fetchEventData(idVerification.eventId)
      interval = setInterval(
        eventId => {
          console.count()
          // this was being used to trigger a retry after a period of time but I don't believe that is the desire any longer
          // if (new Date().getTime() - startTime > tenMinutes) {
          //   clearInterval(interval);
          //   setStatus(VerificationStatus.RETRY)
          //   return;
          // }
          fetchEventData(eventId)
        },
        refreshInterval,
        idVerification.eventId
      )
      return () => clearInterval(interval)
    }
  }, [idVerification.eventId])

  const AcceptedLayout = () => {
    return (
      <>
        <div className="large_green success-logo">
          <i className="fa-solid fa-circle-check"></i>
        </div>
        <CardText className="card-title">
          Identity Successfully Verified
        </CardText>
        <br />
        <CardText>
          Verification for {props.idItem?.borrower.borrowerFirstName}{" "}
          {props.idItem?.borrower.borrowerLastName} successfully completed{" "}
          {statusUpdated.toLocaleDateString()}
        </CardText>
      </>
    )
  }

  const PendingLayout = () => {
    return (
      <>
        <CardText>
          To get started please click <b>Verify My Identity</b> below. We'll
          send a secure link to the primary cell phone provided in your loan
          application
        </CardText>
        {!spinner ? (
          <span className="large_purple">
            <i className="fa-regular fa-shield-check"></i>
          </span>
        ) : (
          <div className="spinner-container">
            <Spinner className="pending-spinner" />
          </div>
        )}
        <br />
        <button
          disabled={spinner ? true : false}
          className={`button blue_style${spinner ? " disabled" : ""}`}
          onClick={e => onClickGetVerification(e)}
        >
          Verify My Identity
        </button>
        <CardText>
          If this is not the correct phone or you did not receive a text, you
          can try again or please contact your HELOC loan team for assistance.{" "}
          <br />
          Email us at{" "}
          <a className="email_link" href="mailto:heloc@loandepot.com">
            heloc@loandepot.com
          </a>{" "}
          or call us at (866) 790-3940.
        </CardText>
      </>
    )
  }

  const FailureLayout = () => {
    return (
      <>
        <span className="large_purple">
          <i className="fas fa-exclamation-triangle"></i>
        </span>
        {}
        <br />
        <CardText className="card-title">Uh Oh!</CardText>
        <CardText>
          We're having a hard time verifying identity. Our team will reach out
          to your shortly to clear this up. For further assistance please
          contact us at{" "}
          <a href="#" className="support-link">
            heloc@loandepot.com
          </a>{" "}
          <br />
        </CardText>
        <a href="https://www.loandepot.com/" className="return-link">
          Return to LoanDepot.com
        </a>
      </>
    )
  }

  //TODO: need to add a styling for disabled state of verify button
  const StartedLayout = () => {
    return (
      <>
        <CardText>
          To get started please click <b>Verify My Identity</b> below. We'll
          send a secure link to the primary cell phone provided in your loan
          application
        </CardText>
        {!spinner ? (
          <div className="started-icon-text-group">
            <span className="large_purple">
              <i className="fa-regular fa-shield-check"></i>
            </span>
            {status == VerificationStatus.RETRY ? (
              <p className="retry-bold">
                There was an issue with your ID Verification, please retry.
              </p>
            ) : (
              <p className="retry-bold">
                Verification Link sent to phone <br /> ending in *****
                {props.idItem?.borrower.borrowerPrimaryPhone.slice(-4)}
              </p>
            )}
          </div>
        ) : (
          <div className="spinner-container">
            <Spinner className="pending-spinner" />
          </div>
        )}
        <br />
        <button
          disabled={
            status == VerificationStatus.STARTED || spinner ? true : false
          }
          className={`button blue_style ${
            status == VerificationStatus.STARTED || spinner ? "disabled" : ""
          }`}
          onClick={e => onClickGetVerification(e)}
        >
          Verify My Identity
        </button>
        <CardText>
          If this is not the correct phone or you did not receive a text, you
          can try again or please contact your HELOC loan team for assistance.{" "}
          <br />
          Email us at{" "}
          <a className="email_link" href="mailto:heloc@loandepot.com">
            heloc@loandepot.com
          </a>{" "}
          or call us at (866) 790-3940.
        </CardText>
      </>
    )
  }

  return (
    <div className="content">
      <Card className="setSizeW">
        <CardBody>{determineLayoutForStatus()}</CardBody>
      </Card>
    </div>
  )
}
export default Verification;
