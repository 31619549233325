import { createSlice } from "@reduxjs/toolkit"

const initialState = {
  globalTheme: {},
  functionalities: {},
}

const TemplateConfigSlice = createSlice({
  name: "TemplateConfig",
  initialState,
  reducers: {
    setTemplateConfig: (state, action) => {
      return { ...state, ...action.payload }
    },
  },
})

export const { setTemplateConfig } = TemplateConfigSlice.actions

export default TemplateConfigSlice.reducer
