import ChatEmptyIcon from './chat-empty-icon.svg';

const EmptyChatMessage = () => {
  return (
    <div className="empty-chat-message">
      <img src={ChatEmptyIcon} alt="chat empty"/>
      <b>LoanDepot messages</b>
      <p>Send any questions directly to your loan team.</p>
    </div>
  )
}

export default EmptyChatMessage;
