import React from 'react';

const LoanTeam = ({ members }) => {
  return (
    <ul>
      {members.map((member, index) => {
        return (
          <li key={index} className="member-item">
            <span className="member-avatar">{member.avatar}</span>
            <div className="member-info">
              <span>
                <b>{member.createdBy}</b>
              </span>
              <span>{member.contact}</span>
            </div>
          </li>
        )
      })}
    </ul>
  )
}

export default LoanTeam;