import React from "react";
import { Auth0Provider } from "@auth0/auth0-react";
import { useSelector } from "react-redux";

export const AuthWrapper = ({ children }) => {
  const TemplateConfig = useSelector((state) => state.TemplateConfig?.auth0);

  if (TemplateConfig) {
    const options = {
      domain: TemplateConfig?.domain,
      clientId: TemplateConfig?.clientId,
      redirectUri: `${window.location.origin}${process.env.REACT_APP_HOMEPAGE_PATH}`,
      audience: TemplateConfig?.audience,
      cacheLocation: "localstorage",
      ...(TemplateConfig?.orgId
        ? { organization: TemplateConfig?.orgId }
        : { organization: "borrower-portal-api" }),
    };
    return <Auth0Provider {...options}>{children}</Auth0Provider>;
  } else {
    return <>...getting template config</>;
  }
};
