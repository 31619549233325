/*!

=========================================================
* Paper Dashboard PRO React - v1.3.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useState } from 'react';

import { Button, Card, CardBody, CardSubtitle, CardText, CardTitle } from 'reactstrap';
import { Doughnut } from 'react-chartjs-2';
import { chartExample6 } from '../variables/charts.js';
import LoadingMsg from '../components/LoadingMsg';
import { useAuth0 } from '@auth0/auth0-react';
import { useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';

const fundedBox = () => {
  return (
    <div className='card skinnyBox funded'>
      <CardBody>
        <div>
          <i className='fa-sharp fa-solid fa-stamp stamp-icon'></i>
        </div>
        <p>Application Status</p>
        <p className='xl_'>Funded</p>
      </CardBody>
    </div>
  );
};

let helperLeft = (data) => {
  return data.map((el, id) => {
    return (
      el?.active && (
        <div className='card skinnyBox' key={id}>
          <CardBody>
            <div>
              <i className={el?.icon}></i>
            </div>
            <p>{el?.title}</p>
            <p className='xl_'>{el?.data}</p>
          </CardBody>
        </div>
      )
    );
  });
};

let helperRight = (data) => {
  return data.map((el, id) => {
    return (
      el?.active && (
        <div className='card skinnyBox funded' key={id}>
          <CardBody>
            <div>
              <i className={el?.icon}></i>
            </div>
            <p>{el?.title}</p>
            <p className='xl_'>{el?.data}</p>
          </CardBody>
        </div>
      )
    );
  });
};

const setDate = (date) => {
  let loanStartDate = new Date(date);
  const [onlyDate] = loanStartDate.toISOString().replaceAll('-', '/').split('T');
  console.log(onlyDate);

  let [year, month, day] = onlyDate.split('/');
  return `${month}/${day}/${year}`;
};

const formatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
});

function Loan(props) {
  const [loans] = useState(props?.loanFile);
  const savedLoanCard = JSON.parse(localStorage.getItem('selectedLoan')) ?? loans[0];
  const hasVisitedSplashPage = JSON.parse(localStorage.getItem('hasVisitedSplashPage'));

  const { logout } = useAuth0();
  if (savedLoanCard !== null) {
    const { lineAmount, drawAmount, rateType, apr, rate, loanNumber, monthlyPayment, property, applicationDate, isFunded, programType } =
      savedLoanCard;

    if (!hasVisitedSplashPage && !savedLoanCard?.hasVisitedSplashPage) {
      //!savedLoanCard?.hasVisitedSplashPage) {
      return <Redirect to='/splash' />;
    }

    const TemplateConfig = useSelector((state) => state.TemplateConfig);
    const displayDrawAmountForHeloc = TemplateConfig?.displayDrawAmountForHeloc ?? false;
    const isProgramActive = TemplateConfig?.modules?.loanInfo?.cards?.informationLoan?.active ?? true;
    const typeOfProgram = isProgramActive ? programType : '';

    // Create our number formatter.
    let formatter = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
    });

    const primaryDataset = {
      label: 'Line Of Credit',
      pointRadius: 0,
      pointHoverRadius: 0,
      backgroundColor: getComputedStyle(document.documentElement).getPropertyValue('--primary-color'),
      borderWidth: 1,
      barPercentage: 10,
      data: [lineAmount],
    };

    const secondaryDataset = {
      label: 'Initial Draw Amount',
      pointRadius: 0,
      pointHoverRadius: 0,
      backgroundColor: getComputedStyle(document.documentElement).getPropertyValue('--secondary-color'),
      borderWidth: 1,
      barPercentage: 10,
      data: [drawAmount, lineAmount],
    };

    const data = {
      labels: !displayDrawAmountForHeloc ? ['Line Of Credit'] : ['Line Of Credit', 'Initial Draw Amount'],
      datasets: !displayDrawAmountForHeloc ? [primaryDataset] : [primaryDataset, secondaryDataset],
    };

    let getDate = setDate(applicationDate);
    const monthlyLeftData = [
      {
        title: TemplateConfig?.modules?.loanInfo?.cards?.estimatedMinimumPayment?.title || 'Estimated Monthly Payment',
        data: formatter.format(monthlyPayment),
        icon: 'fa-solid fa-dollar-sign',
        active: TemplateConfig?.modules?.loanInfo?.cards?.estimatedMinimumPayment?.active ?? true,
      },
      {
        title: TemplateConfig?.modules?.loanInfo?.cards?.estimatedAPR?.title || 'Estimated APR',
        data: `${apr}%`,
        icon: 'fa-solid fa-money-bill-1',
        active: TemplateConfig?.modules?.loanInfo?.cards?.estimatedAPR?.active ?? true,
      },
    ];
    const loanRightData = [
      {
        title: TemplateConfig?.modules?.loanInfo?.cards?.applicationDate?.title || 'Application Date',
        data: getDate,
        icon: 'fa-solid fa-calendar-day',
        active: TemplateConfig?.modules?.loanInfo?.cards?.applicationDate?.active ?? true,
      },
      {
        title: TemplateConfig?.modules?.loanInfo?.cards?.rateType?.title || 'Rate Type',
        data: rateType === 'AdjustableRate' ? 'Variable' : rateType,
        icon: 'fa-solid fa-percent',
        active: TemplateConfig?.modules?.loanInfo?.cards?.rateType?.active ?? true,
      },
      {
        title: TemplateConfig?.modules?.loanInfo?.cards?.interestRate?.title || 'Interest Rate',
        //data: `${apr}%`,
        data: `${rate?.toFixed(2)}%`,
        icon: 'fa-solid fa-money-bill-1',
        active: TemplateConfig?.modules?.loanInfo?.cards?.interestRate?.active ?? true,
      },
    ];
    const isVisibleLineCredit = TemplateConfig?.modules?.loanInfo?.cards?.lineCredit?.active ?? true;
    const isVisibleDrawAmount = TemplateConfig?.modules?.loanInfo?.cards?.drawAmount?.active ?? true;

    if (props.loanFileErr) {
      return (
        <div
          style={{
            display: 'flex',
            flexWrap: 'wrap',
            justifyContent: 'center',
            verticalAlign: 'center',
            marginTop: '100px',
          }}>
          <Card
            style={{
              width: '25rem',
            }}>
            <img alt='Sample' src='https://picsum.photos/300/200' />
            <CardBody>
              <CardTitle tag='h5'>Uh-Oh!</CardTitle>
              <CardSubtitle className='mb-2 text-muted' tag='h6'>
                Internal Server Error
              </CardSubtitle>
              <CardText>
                We were unable to access your loan information. Please try logging out and logging back in, or try logging in from a different web
                browser (Edge, Chrome or Firefox). If the issue persists please contact Loan Depot.
              </CardText>
              <Button
                className='button blue_style'
                onClick={() => {
                  localStorage.removeItem('selectedLoan');
                  localStorage.removeItem('hasVisitedSplashPage');
                  logout({
                    returnTo: `${window.location.origin}${process.env.REACT_APP_HOMEPAGE_PATH}`,
                  });
                }}>
                Return to Borrower Portal Login
              </Button>
            </CardBody>
          </Card>
        </div>
      );
    }

    return (
      <div className='content'>
        <div className='loan-holder'>
          <div className='clientInfo'>
            <p style={{ color: 'var(--primary-color)' }}>
              {`${property.streetAddress}. ${property.streetAddress2 ? property.streetAddress2 : ''}`}
              <br />
              <span className='font-no-bold'>{`${typeOfProgram} Information for Loan #${loanNumber}`}</span>
            </p>
          </div>
          <div className='loanData'>
            {isVisibleLineCredit && (
              <div className='loanDataHolder loanFirstBox'>
                {isFunded ? fundedBox() : null}
                <div className='card size_M'>
                  <div className='lineCredit-holder'>
                    {programType === 'HELOC' ? ( // render line of credit if program type is HEL
                      <>
                        <div className='lineCredit'>
                          <p>{TemplateConfig?.modules?.loanInfo?.cards?.lineCredit?.lineOfCredit?.title || 'Line Amount'}</p>
                          <p className='xl__'>{formatter.format(lineAmount)}</p>
                        </div>
                        {displayDrawAmountForHeloc && isVisibleDrawAmount && (
                          <div className='drawAmount'>
                            <p>{TemplateConfig?.modules?.loanInfo?.cards?.lineCredit?.drawAmount?.title || 'Draw Amount'}</p>
                            <p className='xl_'>{formatter.format(drawAmount)}</p>
                          </div>
                        )}
                      </>
                    ) : (
                      <>
                        <div className='lineCredit'>
                          <p>{TemplateConfig?.modules?.loanInfo?.cards?.lineCredit?.loanAmount?.title || 'Loan Amount'}</p>
                          <p className='xl__'>{formatter.format(lineAmount)}</p>
                        </div>
                      </>
                    )}
                  </div>
                  <div className='chart'>
                    <i className='fa-solid fa-circle-dollar' style={{ zIndex: '1' }}></i>
                    <CardBody style={{ height: '175px', left: '-10px' }}>
                      <Doughnut
                        data={data}
                        options={chartExample6.options}
                        className='ct-chart ct-perfect-fourth'
                        height={300}
                        width={456}
                        style={{ position: 'relative', zIndex: '5' }}
                      />
                    </CardBody>
                  </div>
                </div>
                {helperRight(monthlyLeftData)}
              </div>
            )}
            <div className='loanDataHolder loanSecondBox'>{helperLeft(loanRightData)}</div>
          </div>
        </div>
      </div>
    );
  } else {
    return <LoadingMsg />;
  }
}

export default Loan;
