import React, { useEffect, useRef } from "react";
import moment from "moment/moment";

const ChatMessages = ({messages}) => {
  let messagesContainer = useRef(null);

  const scrollToBottom = () => {
    messagesContainer?.scrollIntoView({ behavior: 'smooth' });
  }

  useEffect(() => {
    scrollToBottom();
  }, [messagesContainer])

  useEffect(() => {
    if (messagesContainer) {
      scrollToBottom();
    }
  }, [messages])

  return (
    <ul>
        {messages.map((message, index) => {
        return (
            <li key={index} className={message.createdByType}>
                <span className="message-bubble">
                  <div className='message' ref={(ref) => index === (messages.length - 1) ? messagesContainer = ref : null}>
                      {message.text}
                  </div>
                  <span className="message-date">
                    {moment(message.createdOn).format('MM/DD/YYYY hh:mm a')}
                  </span>
                </span>
            </li>
            )
        })}
    </ul>
  )
}

export default ChatMessages;