import React from "react";

const PlusButton = (props) => {
  const buttonSize = 40;
  const resetFileValuesOnClick = (e) => {
    e.target.value = null;
  }
  return (
    <div style={{ display: "block" }}>
      <label
        htmlFor={props.for}
        className="button blue_style"
        style={{
          margin: "auto",
          width: buttonSize.toString() + "px",
          height: buttonSize.toString() + "px",
          borderRadius: (buttonSize / 2).toString() + "px",
          padding: "0",
          fontSize: buttonSize.toString() + "px",
          color: "white",
          fontWeight: "bold",
          lineHeight: buttonSize.toString() + "px",
        }}
      >
        +
      </label>
      <input
        type="file"
        id={props.for}
        name={props.for}
        multiple
        style={{ visibility: "hidden", display: "none" }}
        onChange={props.onChange}
        onClick={(e) => resetFileValuesOnClick(e)}
      />
    </div>
  );
};

export default PlusButton;
