import Loan from './views/Loan.js';
import Verification from './views/Verification';
import Disclosures from './views/Disclosures';
import PreClosing from './views/PreClosing';
import Closing from './views/Closing';
import AdverseView from './views/Adverse';
import React from 'react';

const routes = [
  {
    path: '/loan-info',
    name: 'Loan Info',
    icon: 'fa-duotone fa-circle-dollar pro-icons',
    component: <Loan />,
    layout: '/admin',
    includeInSidebar: true,
  },
  {
    path: '/verification',
    name: 'ID Verification',
    icon: 'fa-duotone fa-circle-check pro-icons',
    component: <Verification />,
    layout: '/admin',
    includeInSidebar: false,
  },
  {
    path: '/tasks',
    name: 'Tasks',
    icon: 'fa-duotone fa-circle-check pro-icons',
    component: <Disclosures />,
    layout: '/admin',
    includeInSidebar: true,
  },
  {
    path: '/pre_closing',
    name: 'Pre-closing tasks',
    icon: 'fa-duotone fa-circle-check pro-icons xl-icon',
    component: <PreClosing />,
    layout: '/admin',
    includeInSidebar: false,
  },
  {
    path: '/closing',
    name: 'Closing',
    icon: 'fa-duotone fa-circle-check pro-icons ',
    component: <Closing />,
    layout: '/admin',
    includeInSidebar: false,
  },

  {
    path: '/adverse',
    name: 'Adverse',
    icon: 'fa-duotone fa-circle-check pro-icons ',
    component: <AdverseView />,
    layout: '/adverse',
    includeInSidebar: false,
  },
];

export default routes;
