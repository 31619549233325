/*!

=========================================================
* Paper Dashboard PRO React - v1.3.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useState } from "react";

import {
  Card,
  CardBody,
  CardText,
  CardTitle,
  Modal,
  ModalBody,
} from "reactstrap";
import DropzoneComponent from "./components/DropZone";
import LoadingMsg from "../components/LoadingMsg";
import FcRoutes from "../services/eventService";
import { useAuth0 } from "@auth0/auth0-react";
import CompletedZoneComponent from "./components/CompletedZone";

const PreClosing = (props) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedTask, setSelectedTask] = useState(null);
  const { getAccessTokenSilently } = useAuth0();
  const [taskBoxes] = React.useState(null)
  const [errorCount, setErrorCount] = useState(0)
  const [borrower, setBorrower] = useState(null)
  const [closingData] = useState()
  const [loading] = useState(true)
  const [taskToFetchDocs, setTaskToFetchDocs] = useState(null)
  const [spinner] = useState(true)

  const getAccessToken = async () => {
    const token = await getAccessTokenSilently()
    return token
  }

  const handleViewDocuments = taskId => {
    setTaskToFetchDocs(taskId)
  }

  const getSigningRoomData = async signingRoomId => {
    let apiClient = new FcRoutes(await getAccessToken())
    if (errorCount < 15) {
      return apiClient
        .getSigningRoomById(signingRoomId)
        .then(res => {
          return res.data
        })
        .catch(error => {
          setErrorCount(errorCount + 1)
        })
    }
  }

  const toggle = () => {
    setSelectedTask(null)
    setIsOpen(!isOpen)
  }

  const onOpenIframeClick = task => {
    setSelectedTask(task)
    setIsOpen(true)
  }

  const formatDate = date => {
    const newDate = new Date(date)
    const monthName = new Intl.DateTimeFormat("en-US", { month: "short" })
      .format

    return `${monthName(newDate)} ${newDate.getDate()} ${newDate.getFullYear()}`
  }

  const helperFunction = tasks => {
    if (tasks) {
      let boxes = tasks.map((task, index) => {
        let renderedTask
        switch (task.taskType) {
          case "UPLOAD":
            renderedTask = renderUploadTask(task, index)
            break

          case "SCHEDULE CLOSING":
            renderedTask = renderScheduleTask(task)
            break

          default:
            renderedTask = null
        }
        return renderedTask
      })
      return boxes
    }
    return null
  }

  const renderUploadTask = (task, index) => {
    let taskStatus = "notStarted"
    if (task.isCompleted === "true") {
      taskStatus = "completed"
    } else if (task.isInProgress === "true") {
      taskStatus = "inProgress"
    }

    const completed = task.isCompleted === "true"
    return (
      <Card
        key={task.taskId}
        className="setSizeM"
        style={{ maxHeight: "450px", marginRight: "10px", marginLeft: "10px" }}
      >
        <CardBody>
          {taskStatus === "completed" ? (
            <span className="large_green">
              <i className="fa-regular fa-circle-check"></i>
            </span>
          ) : (
            <>
              {taskStatus === "inProgress" ? (
                <span className="large_purple">
                  <i className="fa-solid fa-refresh"></i>
                </span>
              ) : (
                <span className="large_purple">
                  <i className="fa-regular fa-circle-exclamation"></i>
                </span>
              )}
            </>
          )}

          <br />
          <CardTitle style={{ maxHeight: "90px", overflow: "auto" }}>
            {task.description}
          </CardTitle>
          <CardText>
            {!completed ? "Upload Documents" : "Task Completed"}
          </CardText>
          {taskStatus !== "completed" ? (
            <DropzoneComponent
              cardButton={task.cardButton}
              task={task}
              index={index}
              props={props}
            />
          ) : (
            <CompletedZoneComponent
              cardButton={task.cardButton}
              task={task}
              loanId={props.user.user_metadata.loanId}
              index={index}
              props={props}
              currentTaskLoad={taskToFetchDocs}
            />
          )}
          {taskStatus === "completed" && (
            <>
              <p className={`preClosingText}`}>
                This task has been completed. You may view the documents you
                submitted.
              </p>
              <div>
                <button
                  className="btn btn-primary btn-block w-100"
                  style={{
                    borderRadius: "10px",
                    padding: "15px",
                  }}
                  onClick={() => handleViewDocuments(task.taskId)}
                  disabled={taskToFetchDocs === task.taskId}
                >
                  View Documents
                </button>
              </div>
            </>
          )}
        </CardBody>
      </Card>
    )
  }

  const getName = () => {
    if (props.loanFile && props.user) {
      let matchedNamesFromBorrowerList = props.loanFile.borrowers.filter(
        borrower =>
          (!borrower.isPrimary &&
            props.user.user_metadata.borrowerType == "COBORROWER") ||
          (borrower.isPrimary &&
            props.user.user_metadata.borrowerType == "BORROWER")
      )
      if (
        matchedNamesFromBorrowerList &&
        matchedNamesFromBorrowerList.length > 0
      ) {
        return (
          matchedNamesFromBorrowerList[0].firstName +
          " " +
          matchedNamesFromBorrowerList[0].lastName
        )
      }
    }
    return "Guest"
  }

  const renderScheduleTask = task => {
    const completed = task.isCompleted === "true"
    const inProgress = task.isInProgress === "true"
    const colorClassName = completed ? "large_green" : "large_purple"
    const iconClassName = completed
      ? "fa-solid fa-circle-check"
      : inProgress
      ? "fa-solid fa-refresh"
      : "fa-regular fa-circle-exclamation"

    return (
      <Card key={task.taskId} className="setSizeM card-schedule-content">
        <CardBody>
          <span className={colorClassName}>
            <i className={iconClassName}></i>
          </span>{" "}
          <br />
          <CardTitle className="mb-4">Schedule Closing</CardTitle>
          {!completed && <b className="pb-3">{formatDate(task.dateCreated)}</b>}
          {!completed && (
            <CardText className="mt-4">{task.description}</CardText>
          )}
          {completed && borrower && (
            <span className="borrower-info-text">
              <CardText>
                <b>{getName()}</b>
                <br />
                {`${props.loanFile?.borrowerProperty?.propertyStreetAddress}`}
              </CardText>
            </span>
          )}
          {completed && closingData?.notaryInfo && !loading && (
            <>
              <table className="closing_table">
                <tbody>
                  <tr>
                    <td>Date</td>
                    <td>{closingData.signingDate || ""}</td>
                  </tr>
                  <tr>
                    <td>Time</td>
                    <td>{closingData.signingTime || ""}</td>
                  </tr>
                  <tr>
                    <td>Notary Name</td>
                    <td>{`${closingData.notaryInfo?.notaryFirstName || ""} ${
                      closingData.notaryInfo?.notaryLastName || ""
                    }`}</td>
                  </tr>
                  <tr>
                    <td>Notary Contact</td>
                    <td>
                      {closingData.notaryInfo?.notaryPhoneNumber ||
                        closingData.notaryInfo?.notaryEmail ||
                        ""}
                    </td>
                  </tr>
                </tbody>
              </table>
            </>
          )}
          {completed && loading && (
            <div className="w-100">
              <span className="m-auto d-block p-3">Loading...</span>
            </div>
          )}
          <button
            className={`btn btn-primary btn-block w-100 schedule-button ${
              completed ? "shedule-button-margin" : ""
            }`}
            onClick={() => onOpenIframeClick(task)}
            disabled={false}
          >
            {completed ? "Adjust Appointment" : "Request Reschedule"}
          </button>
        </CardBody>
      </Card>
    )
  }

  const renderIFrameModal = () => {
    return (
      <Modal
        className="iframe-modal"
        isOpen={isOpen}
        toggle={toggle}
        modalTransition={{ timeout: 500 }}
      >
        <ModalBody className="p-0">
          <div className="exit-container">
            <i
              class="fa-sharp fa-solid fa-circle-xmark iframe-exit-icon"
              onClick={() => setIsOpen(false)}
            ></i>
          </div>
          <div
            dangerouslySetInnerHTML={{
              __html: `
							<iframe
								src='${
                  selectedTask?.isCompleted === "true"
                    ? selectedTask?.url + "/reschedule"
                    : selectedTask?.url
                }'
								style="width: 100%; border-radius: 12px; border: 0;"
								allowfullscreen
							/>`,
            }}
            className="schedule-iframe"
          />
        </ModalBody>
      </Modal>
    )
  }

  React.useEffect(() => {
    let isMounted = true
    //get borrower info
    if (props.loanFile) {
      const { borrowers } = props.loanFile
      setBorrower(borrowers[0])
    }
    return () => {
      isMounted = false
    }
  }, [])

  if (spinner || !taskBoxes) {
    return (
      <LoadingMsg
        type="PreClosing"
        message="Please wait while we refresh your tasks..."
      />
    );
  }

  return (
    <div style={{ display: "flex", flexWrap: "wrap" }}>
      {helperFunction(taskBoxes)}
      {renderIFrameModal()}
    </div>
  );
};

export default PreClosing;
