import React from "react";

const  SocialMediaIcons = () => {
    return (
        <div className="social_media_container">
            <a
                className="icon_container"
                href="https://www.facebook.com/loandepotdotcom"
                target="_blank"
            >
                <div className="icon_link">
                    <i className="fa-brands fa-facebook"></i>
                </div>
            </a>
            <a
                className="icon_container"
                href="https://twitter.com/loanDepot"
                target="_blank"
            >
                <div className="icon_link">
                    <i className="fa-brands fa-twitter"></i>
                </div>
            </a>
            <a
                className="icon_container"
                href="https://www.linkedin.com/company/loandepot/"
                target="_blank"
            >
                <div className="icon_link">
                    <i className="fa-brands fa-linkedin-in"></i>
                </div>
            </a>
        </div>
    );
};

export default SocialMediaIcons;
