/*!

=========================================================
* Paper Dashboard PRO React - v1.3.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
// javascript plugin used to create scrollbars on windows
import PerfectScrollbar from "perfect-scrollbar";
import { Route, Switch, useLocation } from "react-router-dom"
import AdminNavbar from "../components/Navbars/AdminNavbar.js"
import Footer from "../components/Footer/Footer.js"
import Sidebar from "../components/Sidebar/Sidebar.js"
import routes from "../routes.js"
import Disclosures from "../views/Disclosures"
import Verification from "../views/Verification"
import Loan from "../views/Loan"
import PreClosing from "../views/PreClosing"
import Closing from "../views/Closing"
import Chat from "../components/Chat"

let ps

function Admin(props) {
  const location = useLocation()
  const mainPanel = React.useRef()

  React.useEffect(() => {
    if (navigator.platform.indexOf("Win") > -1) {
      document.documentElement.className += " perfect-scrollbar-on"
      document.documentElement.classList.remove("perfect-scrollbar-off")
      ps = new PerfectScrollbar(mainPanel?.current)
    }
    return function cleanup() {
      if (navigator.platform.indexOf("Win") > -1) {
        ps.destroy()
        document.documentElement.className += " perfect-scrollbar-off"
        document.documentElement.classList.remove("perfect-scrollbar-on")
      }
    }
  })
  React.useEffect(() => {
    if (
      document?.documentElement &&
      document?.scrollingElement &&
      mainPanel?.current
    ) {
      document.documentElement.scrollTop = 0
      document.scrollingElement.scrollTop = 0
      mainPanel.current.scrollTop = 0
    }
  }, [location])
  const getRoutes = () => {
    return (
      <>
        <Route
          path="/admin/loan-info"
          render={() => (
            <Loan
              loading={props.loading}
              user={props.user}
              loanFile={props.loanFile}
              loanFileErr={props.loanFileErr}
            />
          )}
        />
        <Route
          path="/admin/verification"
          render={() => (
            <Verification
              loading={props.loading}
              user={props.user}
              idItem={props.idItem}
              setIsFraud={props.setIsFraud}
              identityVerificationId={props.identityVerificationId}
              setIdentityVerificationId={props.setIdentityVerificationId}
              idErr={props.idErr}
            />
          )}
        />
        <Route
          path="/admin/tasks"
          render={() => (
            <Disclosures
              loading={props.loading}
              user={props.user}
              roomInfo={props.roomInfo}
              disclosuresSpinner={props.disclosuresSpinner}
            />
          )}
        />
        <Route
          path="/admin/pre_closing"
          render={() => (
            <PreClosing
              loading={props.loading}
              user={props.user}
              loanFile={props.loanFile}
            />
          )}
        />
        <Route
          path="/admin/closing"
          render={() => (
            <Closing
              loading={props.loading}
              user={props.user}
              loanFile={props.loanFile}
              apiClient={props.apiClient}
              token={props.token}
            />
          )}
        />
      </>
    )
  }
  const handleMiniClick = () => {
    document.body.classList.toggle("sidebar-mini")
  }
  console.log('routes with sidebar:', routes.filter(x => x.includeInSidebar))
  return (
    <div className="wrapper">
      <Sidebar
        {...props}
        routes={routes.filter(x => x.includeInSidebar)}
        activeColor="info"
      />
      <div className="main-panel" ref={mainPanel}>
        <AdminNavbar {...props} handleMiniClick={handleMiniClick} />
        <Switch>{getRoutes()}</Switch>
        {props.location.pathname.indexOf("full-screen-map") !== -1 ? null : (
          <Footer fluid />
        )}
      </div>

      <Chat
        user={props.user}
        loanFile={props.loanFile}
        apiClient={props.apiClient}
        messages={props.messages}
        messageCount={props.messageCount}
        loanTeam={props.loanTeam}
        loading={props.loadingChat}
        setMessageCount={props.setMessageCount}
      />
    </div>
  )
}

export default Admin;
