/*!

=========================================================
* Paper Dashboard PRO React - v1.3.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react"
// javascript plugin used to create scrollbars on windows
import PerfectScrollbar from "perfect-scrollbar"
import SplashNavbar from "../components/Navbars/SplashNavbar.js"
import { Card, CardBody, CardText } from "reactstrap"
let ps
function Fraud(props) {
  const [setSidebarMini] = React.useState(false)
  const mainPanel = React.useRef()

  const handleMiniClick = () => {
    if (document.body.classList.contains("sidebar-mini")) {
      setSidebarMini(false)
    } else {
      setSidebarMini(true)
    }
    document.body.classList.toggle("sidebar-mini")
  }

  React.useEffect(() => {
    if (navigator.platform.indexOf("Win") > -1) {
      document.documentElement.className += " perfect-scrollbar-on"
      document.documentElement.classList.remove("perfect-scrollbar-off")
      ps = new PerfectScrollbar(mainPanel?.current)
    }
    return function cleanup() {
      if (navigator.platform.indexOf("Win") > -1) {
        ps.destroy()
        document.documentElement.className += " perfect-scrollbar-off"
        document.documentElement.classList.remove("perfect-scrollbar-on")
      }
    }
  })

  return (
    <div className="wrapper">
      <SplashNavbar {...props} handleMiniClick={handleMiniClick} />
      <div className="content">
        <Card className="setSizeW">
          <CardBody>
            <span className="large_purple">
              <i className="fas fa-exclamation-triangle"></i>
            </span>
            {}
            <br />
            <CardText>Uh Oh!</CardText>
            <CardText>
              We're having a hard time verifying identity. Our team will reach
              out to your shortly to clear this up. For further assistance
              please contact us at <a href="#">heloc@loandepot.com</a> <br />
              <a href="https://www.loandepot.com/">
                <button className="button blue_style">
                  Return to LoanDepot.com
                </button>
              </a>
            </CardText>
          </CardBody>
        </Card>
      </div>
    </div>
  )
}

export default Fraud;
