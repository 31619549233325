import React from "react";
import {
  Modal,
  ModalBody,
  ModalHeader,
  Col
} from "reactstrap";

function CodeModal(props) {
     const toggle = () => {
        console.log(13, 'Toggle')
    };

	return (
    <Modal
      isOpen={props.isOpen}
      className="code-modal"
      toggle={toggle}
      modalTransition={{ timeout: 500 }}
    >
      <ModalHeader className="my-2">
        <i
          className="fa-solid fa-circle-xmark codemodal-exit-icon mt-1"
          onClick={() => props.close()}
        ></i>
      </ModalHeader>
      <ModalBody className="p-0">
        <Col className="justify-content-center">
          <p className="d-flex justify-content-center">
            This is your Authentication Code to view documents.
          </p>
          <h3 className="d-flex justify-content-center">
            {props.authenticationCode}
          </h3>
        </Col>
      </ModalBody>
    </Modal>
  )
}

export default CodeModal;